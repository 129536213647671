import { TarifasApi } from './../models/containers/tarifas-api.model';
import { HttpClient } from '@angular/common/http';
import { API_REST } from './../url.constants';
import { Injectable, NgModule } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TarifasService {
  baseUrl = API_REST + "tarifas/";

  constructor(private http: HttpClient) {}

  getTarifas(id: number, nombre: string = null, tipo: string) {
    if (nombre) {
      return this.http.get<TarifasApi>(`${this.baseUrl}${id}`, {'params': {'nombre':nombre, 'tipo': tipo}});
    } else {
      return this.http.get<TarifasApi>(`${this.baseUrl}${id}`, {'params': {'tipo': tipo}});
    }
    
  }
}
