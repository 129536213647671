import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { ApiService } from "src/app/services/api.service";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";
import { NAME_APP } from "src/app/url.constants";

@Component({
  selector: "app-main-no-direccionamientos-reporte",
  templateUrl: "./main-no-direccionamientos-reporte.component.html",
  styleUrls: ["./main-no-direccionamientos-reporte.component.scss"]
})
export class MainNoDireccionamientosReporteComponent implements OnInit {
  now = moment();
  momento = moment;
  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFinal: string = this.fechaInicio;
  primera: boolean = false;
  cargando: boolean = false;

  eps: Eps = null;
  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - No Direccionamientos`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  busqueda() {
    this.cargando = true;
    this.apiService.noDireccionamientosService
      .getByFechaFile(
        this.regimen.codigo,
        this.fechaInicio,
        this.fechaFinal
      )
      .subscribe(
        response => {
          this.apiService.utilService.downloadFile(
            response,
            "Reporte no direccionamiento",
            "txt"
          );
          this.cargando = false;
          this.primera = true;
        },
        error => {
          this.cargando = false;
          this.primera = true;
          console.error(error);
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
