import { NAME_APP } from './../../url.constants';
import { Router } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { TIPOS_DOCUMENTOS } from './../../data/datos.constants';
import { JwtResponse } from './../../auth/jwt-response';
import { Eps } from './../../models/eps.model';
import { Component, OnInit } from '@angular/core';
import { EpsSalida } from 'src/app/models/salida/eps-salida.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main-eps-add',
  templateUrl: './main-eps-add.component.html',
  styleUrls: ['./main-eps-add.component.scss']
})
export class MainEpsAddComponent implements OnInit {

  eps: Eps;
  epsSalida: EpsSalida;
  epsForm: FormGroup;

  info: JwtResponse;

  tipos = TIPOS_DOCUMENTOS;

  constructor(
    private token: TokenStorageService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Registrar Eps`);
    this.token.validate();
    this.info = this.token.getInfo();

    this.epsForm = this.fb.group({
      nombre: ["", Validators.required],
      direccion: [""],
      email: ["", Validators.required],
      identificacion: ["", Validators.required],
      tipoIdentificacion: [this.tipos[0].value, Validators.required]
    });
  }

  onSubmit() {
    this.epsSalida = Object.assign({}, this.epsForm.value);

    this.epsSalida.activo = true;

    this.apiService.epsService.saveEps(this.epsSalida).subscribe(
      data => {
        this.router.navigate(["/main/eps"]);
      },
      error => {
        console.log(error);
        this.apiService.notifService.error("Error", error);
      }
    );
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
