import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { ApiService } from "src/app/services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NoDireccionamiento } from "src/app/models/msumin/no-direccionamiento-model";
import { NoDireccionamientoDataSalida } from "src/app/models/salida/no-direccionamiento-data-salida.model";
import Swal from "sweetalert2";
import { PacienteRel } from "src/app/models/paciente-rel.model";
import { Observable } from "rxjs";
import { Select2OptionData } from "ng2-select2";
import { CausaNoEntrega } from "src/app/models/causa-no-entrega.model";
import { NoDireccionamientoData } from "src/app/models/msumin/no-direccionamiento-data.model";

@Component({
  selector: "app-modal-no-direccionamiento-detalle",
  templateUrl: "./modal-no-direccionamiento-detalle.component.html",
  styleUrls: ["./modal-no-direccionamiento-detalle.component.scss"]
})
export class ModalNoDireccionamientoDetalleComponent implements OnInit {
  @Input() public noDireccionamiento: NoDireccionamiento;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Output() public updateNoDireccionamiento = new EventEmitter();

  direccionamientoForm: FormGroup = null;

  cargando: boolean = false;

  prescripcion: PrescripcionApi = null;

  salidaEdit: NoDireccionamientoDataSalida = null;
  causasNoEntrega: CausaNoEntrega[] = [];

  public serviciosData: Observable<Array<Select2OptionData>>;
  public selectOptions: Select2Options;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps && this.noDireccionamiento && this.prescripcion) {
      let medicamento = this.getMedicamento();

      let paciente: PacienteRel = null;
      try {
        paciente = this.prescripcion.prescripcion.rel.rel;
      } catch (error) {}

      let dirSave: NoDireccionamientoData = null;
      try {
        dirSave = this.noDireccionamiento.rel.noDir;
      } catch (error) {}

      this.direccionamientoForm = this.fb.group({
        numPrescripcion: [
          this.noDireccionamiento.NoPrescripcion,
          Validators.required
        ],
        codEps: [
          this.prescripcion.prescripcion.CodEPS,
          Validators.required
        ],
        idNoDireccionamiento: [
          this.noDireccionamiento.IDNODireccionamiento,
          Validators.required
        ],
        codHabPrestador: [
          this.prescripcion.prescripcion.CodHabIPS,
          Validators.required
        ],
        tipoIdPrestador: [
          dirSave ? dirSave.tipoIdPrestador : "",
          Validators.required
        ],
        numIdPrestador: [
          dirSave ? dirSave.numIdPrestador : "",
          Validators.required
        ],
        codMunicipio: [
          this.prescripcion.prescripcion.CodDANEMunIPS,
          Validators.required
        ],
        tipoIdPaciente: [
          this.noDireccionamiento.TipoIDPaciente,
          Validators.required
        ],
        numIdPaciente: [
          this.noDireccionamiento.NoIDPaciente,
          Validators.required
        ],
        regimenPaciente: [
          this.regimen.codigo == "COPC" ? "RC" : "RS",
          Validators.required
        ],
        sexoPaciente: [
          dirSave ? dirSave.sexoPaciente : paciente ? paciente.sexo : "",
          Validators.required
        ],
        fechaNacPaciente: [
          dirSave
            ? dirSave.fechaNacPaciente
            : paciente
            ? paciente.fechaNacimiento
            : "",
          Validators.required
        ],
        codAmbAtencion: [this.transformacionAmbitoAtencion(this.prescripcion.prescripcion.CodAmbAte)],
        codDiagPrinc: [this.prescripcion.prescripcion.CodDxPpal],
        codDiagRel1: [this.prescripcion.prescripcion.CodDxRel1],
        codDiagRel2: [this.prescripcion.prescripcion.CodDxRel2],
        tipoServ: [this.noDireccionamiento.TipoTec, Validators.required],
        codiServ: [
          dirSave != null ? dirSave.codiServ : "",
          Validators.required
        ],
        tipoMed: [medicamento ? medicamento.TipoMed : ""],
        dispIncluido: [""],
        tipoPrestacion: [this.getTipoPrestacion(), Validators.required],
        causalNegacion: [
          dirSave
            ? dirSave.causalNegacion
            : this.noDireccionamiento.CausaNoEntrega,
          Validators.required
        ],
        descCausalNegacion: [
          (dirSave ? dirSave.descCausalNegacion : ""),
          Validators.pattern('^[A-Za-z0-9\s]+$')
        ],
        fechaNoDireccionamiento: [
          this.noDireccionamiento.FecNODireccionamiento.substring(0, 10),
          Validators.required
        ],
        fechaPrescripcion: [
          this.noDireccionamiento.FecNODireccionamiento.substring(0, 10),
          Validators.required
        ]
      });

      this.apiService.codigosMipresService
        .getCausasNoEntregaPorTipo(this.noDireccionamiento.TipoTec)
        .subscribe(data => {
          this.causasNoEntrega = data;
        });

      this.selectOptions = {
        width: "100%",
        allowClear: true
      };

      this.serviciosData = this.apiService.codigosMipresService.getServiciosDynamicList(
        this.noDireccionamiento.TipoTec,
        dirSave ? parseInt(dirSave.codiServ) : null
      );
    }
  }

  public changeProv(e: any): void {
    try {
      if (e.value) {
        this.direccionamientoForm.patchValue({
          codiServ: e.value
        });
      }
    } catch (error) {}
  }

  getMedicamento() {
    try {
      return this.prescripcion.medicamentos.filter(
        item => item.ConOrden == this.noDireccionamiento.ConTec
      )[0];
    } catch (error) {}
    return null;
  }

  public getPrestador() {
    if (!this.noDireccionamiento.rel.noDir) {
      this.apiService.proveedoresService
        .getProveedor(this.prescripcion.prescripcion.CodHabIPS)
        .subscribe(prov => {
          if (prov) {
            this.direccionamientoForm.patchValue({
              tipoIdPrestador: prov.tipoId,
              numIdPrestador: prov.identificacion
            });
          }
        });
    }
  }

  getTipoPrestacion() {
    try {
      let conOrden = this.noDireccionamiento.ConTec;
      switch (this.noDireccionamiento.TipoTec) {
        case "M":
          return this.prescripcion.medicamentos.filter(
            item => item.ConOrden == conOrden
          )[0].TipoPrest;
        case "D":
          return this.prescripcion.dispositivos.filter(
            item => item.ConOrden == conOrden
          )[0].TipoPrest;
        case "S":
          return this.prescripcion.serviciosComplementarios.filter(
            item => item.ConOrden == conOrden
          )[0].TipoPrest;
        case "P":
          return this.prescripcion.procedimientos.filter(
            item => item.ConOrden == conOrden
          )[0].TipoPrest;
        case "N":
          return this.prescripcion.productosnutricionales.filter(
            item => item.ConOrden == conOrden
          )[0].TipoPrest;
        default:
          break;
      }
    } catch (error) {}
    return "";
  }

  showModal() {
    this.cargando = true;
    this.apiService.prescripcionesService
      .getPorNumero(
        this.eps.identificacion,
        this.regimen.codigo,
        this.noDireccionamiento.NoPrescripcion
      )
      .subscribe(data => {
        if (data && data.prescripciones && data.prescripciones.length>0) {
          this.prescripcion = data.prescripciones[0];
          this.getPrestador();
          this.ngOnInit();
          this.cargando = false;
        } else {
          Swal.fire({
            title: "Inconsistencia",
            type: "error",
            text:
              "No se logro cargar la prescripción asociada al no direccionamiento, intente cambiando el regimen en la busqueda."
          });
          this.hideModal();
        }
      });
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  onSubmit() {
    this.salidaEdit = Object.assign({}, this.direccionamientoForm.value);

    this.apiService.noDireccionamientosService
      .enviarData(this.eps.identificacion, this.regimen.codigo, this.salidaEdit)
      .subscribe(
        data => {
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El no direccionamiento se ha realizado con exito"
          });
          this.updateNoDireccionamiento.emit(data);
          this.hideModal();
        },
        error => {
          console.log(error);
          this.apiService.notifService.error("Error", error);
        }
      );
  }
  
  prescripcionPdf() {
    Swal.fire({
      title: 'Generando archivo ... ',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    this.apiService.prescripcionesService.getPorNumeroPdf(this.eps.identificacion, this.regimen.codigo, this.prescripcion.prescripcion.NoPrescripcion).subscribe(
      response => {
        this.apiService.utilService.downloadFile(response, 'Prescripción - '+ this.prescripcion.prescripcion.NoPrescripcion, 'pdf');
      }, error => {
        console.log(error);
        this.apiService.notifService.error('Error', error);
      }
    )
  }

  transformacionAmbitoAtencion(ambito: number): number {
    switch (ambito) {
      case 11: // Ambulatorio - priorizado
        return 2;
      case 12: // Ambulatorio - no priorizado
        return 1;
      case 21: // Hospitalario - Domiciliario
        return 3;
      case 22: // Hospitalario - Internación
        return 4;
      case 30: // Urgencias
        return 5;
    }
    return ambito;
  }

  get descCausalNegacion() { return this.direccionamientoForm.get('descCausalNegacion'); }
}
