import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-eps-edit',
  templateUrl: './main-eps-edit.component.html',
  styleUrls: ['./main-eps-edit.component.scss']
})
export class MainEpsEditComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
