import { DatoFacturadoAnularSalida } from './../models/mfactu/dato-facturado-anular-salida.model';
import { DatoFacturadoSalida } from './../models/mfactu/dato-facturado-salida.model';
import { API_REST } from './../url.constants';
import { HttpClient } from '@angular/common/http';
import { DatosFacturadosApi } from './../models/containers/datos-facturados-api.model';
import { IdentificadoresDatosFacturadosApi } from './../models/containers/identificadores-datos-facturados.model';
import { MensajesAnulacionApi } from './../models/containers/mensajes-anulacion-api.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatosFacturadosService {
  baseUrl = API_REST + "datosfacturado/";

  constructor(private http: HttpClient) {}

  byFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<DatosFacturadosApi>(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}`
    );
  }

  byPrescripcion(nit: string, regimen: string, prescripcion: string) {
    return this.http.get<DatosFacturadosApi>(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${prescripcion}`
    );
  }

  enviar(nit: string, regimen: string, numPresc: string, salida: DatoFacturadoSalida) {
    return this.http.post<IdentificadoresDatosFacturadosApi>(
      `${this.baseUrl}enviar/${nit}/${regimen}/${numPresc}`,
      salida
    );
  }

  facturaRips(nit: string, regimen: string, numPresc: string, salida: any) {
    return this.http.post<boolean>(
      `${this.baseUrl}vincular-factura/${nit}/${regimen}/${numPresc}`,
      salida
    );
  }

  anular(nit: string, regimen: string, numPresc: string, salida: DatoFacturadoAnularSalida) {
    return this.http.post<MensajesAnulacionApi>(
      `${this.baseUrl}anular/${nit}/${regimen}/${numPresc}`,
      salida
    );
  }
}
