import Swal from 'sweetalert2';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { Component, OnInit } from '@angular/core';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-main-cargue-codigos',
  templateUrl: './main-cargue-codigos.component.html',
  styleUrls: ['./main-cargue-codigos.component.scss']
})
export class MainCargueCodigosComponent implements OnInit {

  tipo = 1;
  cargando = false;
  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };

  nameFile: string = null;

  constructor(private apiService: ApiService, private router: Router) { }

  ngOnInit() {
  }

  handleFileInput(event: any) {
    this.selectedFiles = event.target.files;
    this.nameFile = this.selectedFiles.item(0).name;
    this.currentFileUpload = null;
    this.progress.percentage = 0;
  }

  onSubmit() {
    this.progress.percentage = 0;
    this.cargando = true;

    this.currentFileUpload = this.selectedFiles && this.selectedFiles.length!=0 ? this.selectedFiles.item(0):null;
    if (this.tipo == 1) {
      this.apiService.codigosMipresService.saveCums(this.currentFileUpload).subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            if (event.ok) {
              this.currentFileUpload = null;
              this.progress.percentage = 0;
              Swal.fire('Cargue realizado', `El cargue de los códigos CUM se ha realizado`, 'success');
              this.cargando=false
            } else {
              this.apiService.notifService.error("Error", event);
              this.cargando=false
            }
          }
        },
        error => {
          console.error(error);
          this.cargando=false
        }
      );
    }

  }

}
