import { NAME_APP } from './../../url.constants';
import { Router } from "@angular/router";
import { ApiService } from "./../../services/api.service";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import { Eps } from "./../../models/eps.model";
import { JwtResponse } from "./../../auth/jwt-response";
import { UsuarioSalida } from "./../../models/salida/usuario-salida.model";
import { Component, OnInit } from "@angular/core";
import { Usuario } from "src/app/models/usuario.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { TipoIdentificacion } from "src/app/models/tipo-identificacion.model";
import { Title } from '@angular/platform-browser';

@Component({
  selector: "app-main-usuario-add",
  templateUrl: "./main-usuario-add.component.html",
  styleUrls: ["./main-usuario-add.component.scss"]
})
export class MainUsuarioAddComponent implements OnInit {
  usuario: Usuario;
  usuarioEdit: Usuario;
  usuarioForm: FormGroup;

  info: JwtResponse;

  epss: Eps[] = [];
  eps: Eps = null;

  tipos = TIPOS_DOCUMENTOS;

  constructor(
    private token: TokenStorageService,
    private apiService: ApiService,
    private fb: FormBuilder,
    private router: Router,private titleService: Title
    ) {}

    ngOnInit() {
      this.titleService.setTitle(`${NAME_APP} - Registrar usuario`);
    this.token.validate();
    this.info = this.token.getInfo();
    this.usuario = new Usuario();

    this.usuarioForm = this.fb.group({
      nombres: ["", Validators.required],
      apellidos: ["", Validators.required],
      email: ["", Validators.required],
      identificacion: ["", Validators.required],
      tipoId: [this.tipos[0].value, Validators.required],
      auditor: [false]
    });

    if (this.token.isRol("ROLE_ADMIN")) {
      this.apiService.epsService.getListaEps().subscribe(data => {
        if (data.epss) {
          this.epss = data.epss;
          if (this.epss.length > 0) {
            this.eps = this.epss[0];
          }
        }
      });
    } else {
      // this.eps = this.token.getUser().eps;
    }
  }

  onSubmit() {
    this.usuarioEdit = Object.assign({}, this.usuarioForm.value);

    this.usuarioEdit.tipoId = new TipoIdentificacion();
    this.usuarioEdit.tipoId.tipo = this.usuarioForm.get("tipoId").value;

    let usuarioSave = new UsuarioSalida();

    usuarioSave.apellidos = this.usuarioEdit.apellidos;
    usuarioSave.email = this.usuarioEdit.email;
    usuarioSave.enable = true;
    usuarioSave.identificacion = this.usuarioEdit.identificacion;

    usuarioSave.nit = this.eps ? this.eps.identificacion : "";
    usuarioSave.nombres = this.usuarioEdit.nombres;
    usuarioSave.auditor = this.usuarioEdit.auditor;
    usuarioSave.tipoId = this.usuarioForm.get("tipoId").value;

    this.apiService.usuarioService.saveUsuario(usuarioSave).subscribe(
      data => {
        if (data.usuario) {
          this.router.navigate(["/main/usuarios"]);
        } else {
          this.apiService.notifService.warning('Datos invalidos', 'Los datos enviados no pueden ser procesados');
        }
      },
      error => {
        console.log(error);
        this.apiService.notifService.error("Error", error);
      }
    );
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
