import { API_REST_CUM_OTROS, API_REST_CUM_PENDIENTES, API_REST_CUM_VENCIDOS, API_REST_CUM_VIGENTES } from "./../url.constants";
import { Injectable } from "@angular/core";
import axios from "axios";

@Injectable({
  providedIn: "root"
})
export class CumService {

  constructor() {}

  /**
   * Busqueda de codigos cums en datos abiertos
   * @param tipo uno vigentes, dos vencidos, tres pendientes, cuatro otros
   * @param producto 
   * @param principioactivo 
   * @param expediente 
   */
  getByProductoOrPrincipioActivoOrExpediente(tipo: number, producto: string, principioactivo: string, expediente: string) {

    let url = API_REST_CUM_VIGENTES;

    switch (tipo) {
      case 1:
        url = API_REST_CUM_VIGENTES;
        break;
      case 2:
        url = API_REST_CUM_VENCIDOS;
        break;
      case 3:
        url = API_REST_CUM_PENDIENTES;
        break;
      case 4:
        url = API_REST_CUM_OTROS;
        break;
    
      default:
        break;
    }

    if (expediente && expediente.trim().length>0) {
      url+=`?$where=expediente=${expediente}`;
    } else if (producto && producto.trim().length>0) {
      url+=`?$where=producto%20like%20%27%25${producto}%25%27`;
    } else if (principioactivo && principioactivo.trim().length>0) {
      url+=`?$where=principioactivo%20like%20%27%25${principioactivo}%25%27`;
    }
    url+=`&$order=consecutivocum`;
    console.log("url busqueda cums: "+url);
    return axios.get(url);
  }
}
