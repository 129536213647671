import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import { NAME_APP } from 'src/app/url.constants';
import { Eps } from 'src/app/models/eps.model';
import { Title } from '@angular/platform-browser';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { ApiService } from 'src/app/services/api.service';
import { Regimen } from 'src/app/models/regimen.model';
import * as moment from 'moment';

@Component({
  selector: 'app-main-reporte-dinamico',
  templateUrl: './main-reporte-dinamico.component.html',
  styleUrls: ['./main-reporte-dinamico.component.scss']
})
export class MainReporteDinamicoComponent implements OnInit {
  keepSorted = false;
  filter = true;
  sourceLeft = true;
  format = {
    add: "Agregar",
    remove: "Eliminar",
    all: "Marcar",
    none: "Desmarcar",
    direction: "left-to-right",
    draggable: true,
    locale: undefined,
  };
  tipoBusqueda = "p";
  confirmed = [
    { key: 1, nombre: "ID", type: "D", typeName: "Direccionamiento" },
    {
      key: 2,
      nombre: "IDDireccionamiento",
      type: "D",
      typeName: "Direccionamiento",
    },
    {
      key: 3,
      nombre: "NoPrescripcion",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 4, nombre: "TipoTec", type: "D", typeName: "Direccionamiento" },
    { key: 5, nombre: "ConTec", type: "D", typeName: "Direccionamiento" },
    {
      key: 6,
      nombre: "TipoIDPaciente",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 7, nombre: "NoIDPaciente", type: "D", typeName: "Direccionamiento" },
    { key: 8, nombre: "NoEntrega", type: "D", typeName: "Direccionamiento" },
    { key: 9, nombre: "NoSubEntrega", type: "D", typeName: "Direccionamiento" },

    { key: 22, nombre: "ID", type: "R", typeName: "ReporteEntrega" },
    {
      key: 23,
      nombre: "IDReporteEntrega",
      type: "R",
      typeName: "ReporteEntrega",
    },

    { key: 40, nombre: "ID", type: "S", typeName: "Suministro" },
    { key: 41, nombre: "IDSuministro", type: "S", typeName: "Suministro" },

    { key: 59, nombre: "ID", type: "F", typeName: "Facturacion" },
    { key: 50, nombre: "IDFacturacion", type: "F", typeName: "Facturacion" },

    { key: 78, nombre: "ID", type: "A", typeName: "DatoFacturado" },
    {
      key: 79,
      nombre: "IDDatosFacturado",
      type: "A",
      typeName: "DatoFacturado",
    },
  ];
  key: string;
  display: any;

  source: Array<any> = [
    { key: 1, nombre: "ID", type: "D", typeName: "Direccionamiento" },
    {
      key: 2,
      nombre: "IDDireccionamiento",
      type: "D",
      typeName: "Direccionamiento",
    },
    {
      key: 3,
      nombre: "NoPrescripcion",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 4, nombre: "TipoTec", type: "D", typeName: "Direccionamiento" },
    { key: 5, nombre: "ConTec", type: "D", typeName: "Direccionamiento" },
    {
      key: 6,
      nombre: "TipoIDPaciente",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 7, nombre: "NoIDPaciente", type: "D", typeName: "Direccionamiento" },
    { key: 8, nombre: "NoEntrega", type: "D", typeName: "Direccionamiento" },
    { key: 9, nombre: "NoSubEntrega", type: "D", typeName: "Direccionamiento" },
    { key: 10, nombre: "TipoIDProv", type: "D", typeName: "Direccionamiento" },
    { key: 11, nombre: "NoIDProv", type: "D", typeName: "Direccionamiento" },
    { key: 12, nombre: "CodMunEnt", type: "D", typeName: "Direccionamiento" },
    { key: 13, nombre: "FecMaxEnt", type: "D", typeName: "Direccionamiento" },
    {
      key: 14,
      nombre: "CantTotAEntregar",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 15, nombre: "DirPaciente", type: "D", typeName: "Direccionamiento" },
    {
      key: 16,
      nombre: "CodSerTecAEntregar",
      type: "D",
      typeName: "Direccionamiento",
    },
    { key: 17, nombre: "NoIDEPS", type: "D", typeName: "Direccionamiento" },
    { key: 18, nombre: "CodEPS", type: "D", typeName: "Direccionamiento" },
    {
      key: 19,
      nombre: "FecDireccionamiento",
      type: "D",
      typeName: "Direccionamiento",
    },
    {
      key: 20,
      nombre: "EstDireccionamiento",
      type: "D",
      typeName: "Direccionamiento",
    },
    {
      key: 21,
      nombre: "FecAnulacion",
      type: "D",
      typeName: "Direccionamiento",
    },

    { key: 22, nombre: "ID", type: "R", typeName: "ReporteEntrega" },
    {
      key: 23,
      nombre: "IDReporteEntrega",
      type: "R",
      typeName: "ReporteEntrega",
    },
    {
      key: 24,
      nombre: "NoPrescripcion",
      type: "R",
      typeName: "ReporteEntrega",
    },
    { key: 25, nombre: "TipoTec", type: "R", typeName: "ReporteEntrega" },
    { key: 26, nombre: "ConTec", type: "R", typeName: "ReporteEntrega" },
    {
      key: 27,
      nombre: "TipoIDPaciente",
      type: "R",
      typeName: "ReporteEntrega",
    },
    { key: 28, nombre: "NoIDPaciente", type: "R", typeName: "ReporteEntrega" },
    { key: 29, nombre: "NoEntrega", type: "R", typeName: "ReporteEntrega" },
    { key: 30, nombre: "EstadoEntrega", type: "R", typeName: "ReporteEntrega" },
    {
      key: 31,
      nombre: "CausaNoEntrega",
      type: "R",
      typeName: "ReporteEntrega",
    },
    {
      key: 32,
      nombre: "ValorEntregado",
      type: "R",
      typeName: "ReporteEntrega",
    },
    {
      key: 33,
      nombre: "CodTecEntregado",
      type: "R",
      typeName: "ReporteEntrega",
    },
    {
      key: 34,
      nombre: "CantTotEntregada",
      type: "R",
      typeName: "ReporteEntrega",
    },
    { key: 35, nombre: "NoLote", type: "R", typeName: "ReporteEntrega" },
    { key: 36, nombre: "FecEntrega", type: "R", typeName: "ReporteEntrega" },
    { key: 37, nombre: "FecRepEntrega", type: "R", typeName: "ReporteEntrega" },
    { key: 38, nombre: "EstRepEntrega", type: "R", typeName: "ReporteEntrega" },
    { key: 39, nombre: "FecAnulacion", type: "R", typeName: "ReporteEntrega" },

    { key: 40, nombre: "ID", type: "S", typeName: "Suministro" },
    { key: 41, nombre: "IDSuministro", type: "S", typeName: "Suministro" },
    { key: 42, nombre: "NoPrescripcion", type: "S", typeName: "Suministro" },
    { key: 43, nombre: "TipoTec", type: "S", typeName: "Suministro" },
    { key: 44, nombre: "ConTec", type: "S", typeName: "Suministro" },
    { key: 45, nombre: "TipoIDPaciente", type: "S", typeName: "Suministro" },
    { key: 46, nombre: "NoIDPaciente", type: "S", typeName: "Suministro" },
    { key: 47, nombre: "NoEntrega", type: "S", typeName: "Suministro" },
    { key: 48, nombre: "UltEntrega", type: "S", typeName: "Suministro" },
    { key: 49, nombre: "EntregaCompleta", type: "S", typeName: "Suministro" },
    { key: 50, nombre: "CausaNoEntrega", type: "S", typeName: "Suministro" },
    {
      key: 51,
      nombre: "NoPrescripcionAsociada",
      type: "S",
      typeName: "Suministro",
    },
    { key: 52, nombre: "ConTecAsociada", type: "S", typeName: "Suministro" },
    { key: 53, nombre: "CantTotEntregada", type: "S", typeName: "Suministro" },
    { key: 54, nombre: "NoLote", type: "S", typeName: "Suministro" },
    { key: 55, nombre: "ValorEntregado", type: "S", typeName: "Suministro" },
    { key: 56, nombre: "FecSuministro", type: "S", typeName: "Suministro" },
    { key: 57, nombre: "EstSuministro", type: "S", typeName: "Suministro" },
    { key: 58, nombre: "FecAnulacion", type: "S", typeName: "Suministro" },

    { key: 59, nombre: "ID", type: "F", typeName: "Facturacion" },
    { key: 50, nombre: "IDFacturacion", type: "F", typeName: "Facturacion" },
    { key: 61, nombre: "NoPrescripcion", type: "F", typeName: "Facturacion" },
    { key: 62, nombre: "TipoTec", type: "F", typeName: "Facturacion" },
    { key: 63, nombre: "ConTec", type: "F", typeName: "Facturacion" },
    { key: 64, nombre: "TipoIDPaciente", type: "F", typeName: "Facturacion" },
    { key: 65, nombre: "NoEntrega", type: "F", typeName: "Facturacion" },
    { key: 66, nombre: "NoFactura", type: "F", typeName: "Facturacion" },
    { key: 67, nombre: "NoIDEPS", type: "F", typeName: "Facturacion" },
    { key: 68, nombre: "CodEPS", type: "F", typeName: "Facturacion" },
    {
      key: 69,
      nombre: "CodSerTecAEntregado",
      type: "F",
      typeName: "Facturacion",
    },
    { key: 70, nombre: "CantUnMinDis", type: "F", typeName: "Facturacion" },
    {
      key: 71,
      nombre: "ValorUnitFacturado",
      type: "F",
      typeName: "Facturacion",
    },
    {
      key: 72,
      nombre: "ValorTotFacturado",
      type: "F",
      typeName: "Facturacion",
    },
    { key: 73, nombre: "CuotaModer", type: "F", typeName: "Facturacion" },
    { key: 74, nombre: "Copago", type: "F", typeName: "Facturacion" },
    { key: 75, nombre: "FecFacturacion", type: "F", typeName: "Facturacion" },
    { key: 76, nombre: "EstFacturacion", type: "F", typeName: "Facturacion" },
    { key: 77, nombre: "FecAnulacion", type: "F", typeName: "Facturacion" },

    { key: 78, nombre: "ID", type: "A", typeName: "DatoFacturado" },
    {
      key: 79,
      nombre: "IDDatosFacturado",
      type: "A",
      typeName: "DatoFacturado",
    },
    { key: 80, nombre: "NoPrescripcion", type: "A", typeName: "DatoFacturado" },
    { key: 81, nombre: "TipoTec", type: "A", typeName: "DatoFacturado" },
    { key: 82, nombre: "ConTec", type: "A", typeName: "DatoFacturado" },
    { key: 83, nombre: "TipoIDPaciente", type: "A", typeName: "DatoFacturado" },
    { key: 84, nombre: "NoIDPaciente", type: "A", typeName: "DatoFacturado" },
    { key: 85, nombre: "NoEntrega", type: "A", typeName: "DatoFacturado" },
    { key: 86, nombre: "CompAdm", type: "A", typeName: "DatoFacturado" },
    { key: 87, nombre: "CodCompAdm", type: "A", typeName: "DatoFacturado" },
    { key: 88, nombre: "CodHom", type: "A", typeName: "DatoFacturado" },
    { key: 89, nombre: "UniCompAdm", type: "A", typeName: "DatoFacturado" },
    { key: 90, nombre: "UniDispHom", type: "A", typeName: "DatoFacturado" },
    { key: 91, nombre: "ValUnMiCon", type: "A", typeName: "DatoFacturado" },
    { key: 92, nombre: "CantTotEnt", type: "A", typeName: "DatoFacturado" },
    { key: 93, nombre: "ValTotCompAdm", type: "A", typeName: "DatoFacturado" },
    { key: 94, nombre: "ValTotHom", type: "A", typeName: "DatoFacturado" },
    {
      key: 95,
      nombre: "FecDatosFacturado",
      type: "A",
      typeName: "DatoFacturado",
    },
    {
      key: 96,
      nombre: "EstDatosFacturado",
      type: "A",
      typeName: "DatoFacturado",
    },
  ];

  now = moment();
  momento = moment;
  filtrado: string = "f";
  maxDias: number = 9;
  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFin: string = this.fechaInicio;
  tipoDocumento: string = "CC";
  documento: string = "";
  prescripcion: string = "";
  tipo: string = "";
  tipoEnlace: string = "";

  cargando: boolean = false;

  tipos = TIPOS_DOCUMENTOS;

  epss: Eps[] = [];
  eps: Eps = null;

  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  onChangeProveedor(proveedor: Eps) {
    this.eps = proveedor;
  }

  ngOnInit() {
    this.display = this.stationLabel;
    this.key = "key";
    this.token.validate();
    this.titleService.setTitle(`${NAME_APP} - Reporte Dinamico`);

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  private stationLabel(item: any) {
    return `${item.typeName}, ${item.nombre}`;
  }

  disabledTipoBusqueda(t: string) {
    if (this.confirmed.filter((item) => item.type == t).length == 0) {
      return true;
    }
    return false;
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

  cambiarFiltro(f: string) {
    this.filtrado = f;
    this.documento = "";
    this.tipoDocumento = this.tipos[0].value;
    this.prescripcion = "";
  }

  busqueda() {
    if (this.eps != null && this.regimen != null) {
      if (!this.disabledTipoBusqueda("D")) {
        Swal.fire({
          title: "Generando archivo ... ",
          allowOutsideClick: false,
          onBeforeOpen: () => Swal.showLoading(),
        });
        let nombreReporte = "general-dinamico";

        switch (this.filtrado) {
          case "f":
            nombreReporte += "-" + this.fechaInicio + "_" + this.fechaFin;
            break;
          case "a":
            nombreReporte += "-" + this.tipoDocumento + "_" + this.documento;
            break;
          case "p":
            nombreReporte += "-" + this.prescripcion;
            break;
        }
        if (this.documento.trim().length == 0) {
          this.documento = "null";
        }
        if (this.prescripcion.trim().length == 0) {
          this.prescripcion = "null";
        }

        if (this.tipoBusqueda == "p") {
          this.apiService.prescripcionesService
            .planReporteGeneralDinamico(
              this.tipo,
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaInicio,
              this.fechaFin,
              this.tipoDocumento,
              this.documento,
              this.prescripcion,
              this.confirmed
            )
            .subscribe(
              (response) => {
                this.apiService.utilService.downloadFile(
                  response,
                  nombreReporte,
                  "xlsx"
                );
              },
              (error) => {
                console.log(error);
                Swal.close();
                this.apiService.notifService.error("Error", error);
              }
            );
        } else {
          this.apiService.tutelasService
            .planReporteGeneralDinamico(
              this.tipo,
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaInicio,
              this.fechaFin,
              this.tipoDocumento,
              this.documento,
              this.prescripcion,
              this.confirmed
            )
            .subscribe(
              (response) => {
                this.apiService.utilService.downloadFile(
                  response,
                  nombreReporte,
                  "xlsx"
                );
              },
              (error) => {
                console.log(error);
                Swal.close();
                this.apiService.notifService.error("Error", error);
              }
            );
        }
      } else {
        Swal.fire(
          "Error",
          "Debe de seleccionar almenos un campo del direccionamiento",
          "info"
        );
      }
    } else {
      Swal.fire("Error", "Debe se seleccionar el regimen de la eps", "info");
    }
  }

}