import { Component, OnInit } from "@angular/core";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";
import { ApiService } from "src/app/services/api.service";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";
import { NAME_APP } from "src/app/url.constants";
import { ValidadorPrescripcionesSalida } from "src/app/models/salida/validador-prescripciones-salida.model";
import Swal from "sweetalert2";
import { HttpResponse } from "@angular/common/http";
import { error } from 'util';

@Component({
  selector: "app-main-validador-prescripciones",
  templateUrl: "./main-validador-prescripciones.component.html",
  styleUrls: ["./main-validador-prescripciones.component.scss"]
})
export class MainValidadorPrescripcionesComponent implements OnInit {
  primera: boolean = false;
  cargando: boolean = false;

  regimen: Regimen = null;
  eps: Eps = null;

  valFacturacion = false;
  valSuministro = true;

  selectedFiles: FileList;
  currentFileUpload: File;

  nameFile: string = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Validador prescripciones`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  handleFileInput(event: any) {
    this.selectedFiles = event.target.files;
    this.nameFile = this.selectedFiles.item(0).name;
    this.currentFileUpload = null;
  }

  validador() {
    Swal.fire({
      title: "Procesando datos",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    this.currentFileUpload =
      this.selectedFiles && this.selectedFiles.length != 0
        ? this.selectedFiles.item(0)
        : null;

    let validadorSalida = new ValidadorPrescripcionesSalida();
    validadorSalida.nit = this.eps.identificacion;
    validadorSalida.regimen = this.regimen.codigo;
    validadorSalida.valFacturacion = this.valFacturacion;
    validadorSalida.valSuministro = this.valSuministro;
    validadorSalida.file = this.currentFileUpload;

    let nombreReporte = "validador_prescripciones_mipres";
    let tipo: string;

    this.apiService.validadorPrescripcionesService
      .validarPrescripciones(validadorSalida)
      .subscribe(response => {
        if (response instanceof Blob) {
          if (response.type == "application/pdf") {
            tipo = "pdf";
          } else if (response.type == "text/plain") {
            tipo = "txt";
          }
          this.apiService.utilService.downloadFile(
            response,
            nombreReporte,
            tipo
          );
        }

        Swal.close();
      }, error => {
        this.apiService.notifService.error("Error", error);
        Swal.close();
      });
  }
}
