import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Direccionamiento } from "../models/msumin/direccionamiento.model";
import { DireccionamientoAnularSalida } from "../models/salida/direccionamiento-anular-salida.model";
import { DireccionamientosApi } from "./../models/containers/direccionamientos-api.model";
import { IdentificadoresDireccionamientoApi } from "./../models/containers/identificadores-direccionamiento-api.model";
import { MensajesAnulacionApi } from "./../models/containers/mensajes-anulacion-api.model";
import { API_REST } from "./../url.constants";

@Injectable({
  providedIn: "root"
})
export class DireccionamientosService {
  baseUrl = API_REST + "direccionamiento/";

  constructor(private http: HttpClient) {}

  getByFechaPresc(nit: string, regimen: string, fecha: string) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byFecha/false/${nit}/${regimen}/${fecha}`
    );
  }

  getByFechaTutel(nit: string, regimen: string, fecha: string) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byFecha/true/${nit}/${regimen}/${fecha}`
    );
  }

  getByPacienteAndFechaPresc(
    nit: string,
    regimen: string,
    fecha: string,
    tipoDoc: string,
    numDoc: string
  ) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byPacienteFecha/false/${nit}/${regimen}/${fecha}/${tipoDoc}/${numDoc}`
    );
  }

  getByPacienteAndFechaTutel(
    nit: string,
    regimen: string,
    fecha: string,
    tipoDoc: string,
    numDoc: string
  ) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byPacienteFecha/true/${nit}/${regimen}/${fecha}/${tipoDoc}/${numDoc}`
    );
  }

  getPrescripcionPresc(nit: string, regimen: string, prescripcion: string) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byPrescripcion/false/${nit}/${regimen}/${prescripcion}`
    );
  }

  getPrescripcionTutel(nit: string, regimen: string, prescripcion: string) {
    return this.http.get<DireccionamientosApi>(
      `${this.baseUrl}byPrescripcion/true/${nit}/${regimen}/${prescripcion}`
    );
  }

  enviar(tutela: boolean, nit: string, regimen: string, entrada: any) {
    return this.http.post<IdentificadoresDireccionamientoApi>(
      `${this.baseUrl}enviar/${tutela}/${nit}/${regimen}`,
      entrada
    );
  }

  auditarFaltante(tutela: boolean, nit: string, regimen: string, entrada: Direccionamiento) {
    return this.http.post<any>(
      `${this.baseUrl}auditar/${tutela}/${nit}/${regimen}`,
      entrada
    );
  }

  reenviarEmailProv(tutela: boolean, nit: string, regimen: string,
    entrada: any) {
    return this.http.post<boolean>(
      `${this.baseUrl}reenviar-email/${tutela}/${nit}/${regimen}`, entrada
    );
  }

  anular(nit: string, regimen: string, entrada: DireccionamientoAnularSalida) {
    return this.http.post<MensajesAnulacionApi>(
      `${this.baseUrl}anular/${nit}/${regimen}`,
      entrada
    );
  }

  filtroDireccionamiento(
    dirs: Direccionamiento[],
    TipoTec: string,
    ConTec: number,
    NoEntrega: number
  ): Direccionamiento {
    let filtro = dirs.filter(
      item =>
        item.EstDireccionamiento != 0 &&
        item.TipoTec == TipoTec &&
        item.ConTec == ConTec &&
        item.NoEntrega == NoEntrega
    );
    if (filtro.length>0) {
      return filtro[0];
    }
    return null;
  }
}
