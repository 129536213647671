import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ReporteDireccionamientosService {
  baseUrl = API_REST;

  constructor(private http: HttpClient) {}

  xlsxDirsTutelas(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}tutela/xlsx-dirs/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  detalladoTutelasXlsx(
    tipo: string,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}tutela/plan-general-all/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  detalladoPrescripcionXlsx(
    tipo: string,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}prescripcion/plan-general-all/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxDirsPrescripciones(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}prescripcion/xlsx-dirs/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxNoDirsPrescripciones(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}no-direccionamiento/xlsx-nodirs/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxInformeST015(
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string
  ) {
    return this.http.get(
      `${this.baseUrl}direccionamiento/st015/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxInformeT760(
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string
  ) {
    return this.http.get(
      `${this.baseUrl}t760/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
