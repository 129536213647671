import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { JwtResponse } from '../../auth/jwt-response';
import { Injectable } from '@angular/core';
import { TOKEN } from '../../url.constants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  valido: boolean = false;
  isLoggedIn: Observable<boolean>;
  private storage = window.localStorage;

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  signOut() {
    this.storage.clear();
  }

  async isToken() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.valido = await this.isLoggedIn.toPromise();

    if (!this.valido) {
      this.signOut();
    }
    return this.valido;
  }

  public saveInfo(info: JwtResponse) {
    this.storage.setItem(TOKEN.TOKEN_KEY, info.accessToken);
    this.storage.setItem(TOKEN.USUARIO_NAME_KEY, info.nombre);
    this.storage.setItem(TOKEN.USUARIO_NIT_KEY, info.nit);
    this.storage.setItem(TOKEN.USUARIO_USERNAME_KEY, info.username);
    this.storage.setItem(TOKEN.USUARIO_ROLES_KEY, JSON.stringify(info.roles));
  }

  public saveToken(token: string) {
    this.storage.removeItem(TOKEN.TOKEN_KEY);
    this.storage.setItem(TOKEN.TOKEN_KEY, token);
  }

  public getToken(): string {
    return this.storage.getItem(TOKEN.TOKEN_KEY);
  }

  public isRol(rol: string):boolean {
    try {
      let roles = JSON.parse(this.storage.getItem(TOKEN.USUARIO_ROLES_KEY));
      return roles.includes(rol);
    } catch (error) {
      console.log(error);
    }

    return false;
  }

  public getInfo(): JwtResponse {

    let info : JwtResponse= new JwtResponse();
    info.nit = this.storage.getItem(TOKEN.USUARIO_NIT_KEY);
    info.nombre = this.storage.getItem(TOKEN.USUARIO_NAME_KEY);
    info.username = this.storage.getItem(TOKEN.USUARIO_USERNAME_KEY);

    return info;
  }

  public validate() {
    this.isToken().then(data => {
      if (!data) {
        this.router.navigate(['inicio/signin']);
      }
    }, error => {
      this.router.navigate(['inicio/signin']);
    });
  }
}
