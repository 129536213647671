import { ProductoNutricional } from './../../../models/mpresc/producto-nutricional.model';
import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-producto-nutricional-detalle',
  templateUrl: './modal-producto-nutricional-detalle.component.html',
  styleUrls: ['./modal-producto-nutricional-detalle.component.scss']
})
export class ModalProductoNutricionalDetalleComponent implements OnInit {
  @Input() public productoNutr: ProductoNutricional;
  @Input() public prescripcion: PrescripcionApi;
  @Output() public showModalPrescripcion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalPrescripcion.emit(this.prescripcion);
  }

}
