import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { Mes } from "src/app/data/datos.constants";
import { Title } from "@angular/platform-browser";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { ApiService } from "src/app/services/api.service";
import { NAME_APP } from "src/app/url.constants";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";

@Component({
  selector: "app-main-tarifas-mensual-informe",
  templateUrl: "./main-tarifas-mensual-informe.component.html",
  styleUrls: ["./main-tarifas-mensual-informe.component.scss"]
})
export class MainTarifasMensualInformeComponent implements OnInit {
  now = moment();
  mesActual = this.now.get("M");
  anioActual = this.now.get("y");

  anios: number[];

  meses = Object.values(Mes).filter(key => !isNaN(Number(Mes[key])));

  anio: number = this.anioActual;
  mes: number = this.mesActual;
  regimen: Regimen = null;
  eps: Eps = null;

  cargando: boolean = false;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.anios = new Array();

    for (let a = -2; a < 3; a++) {
      this.anios.push(this.anioActual + a);
    }
    this.titleService.setTitle(`${NAME_APP} - Consumos detallado`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  busqueda() {
    this.apiService.tarifaMensualService
      .getTarifasExcel(this.regimen.codigo, this.anio, (parseInt(this.mes.toString())+1))
      .subscribe(
        response => {
          this.apiService.utilService.downloadFile(
            response,
            `consumo_mensual_${this.regimen.codigo}_${this.anio}_${Mes[this.mes].toString()}`,
            "xlsx"
          );
          this.cargando = false;
        },
        error => {
          this.cargando = false;
          console.error(error);
          this.apiService.notifService.error("Error", error);
        }
      );
  }
}
