
export class Rol {
  descripcion: string;
  rol: string;

  constructor(rol: string, descripcion: string) {
    this.descripcion = descripcion;
    this.rol = rol;
  }

}
