import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { ProductoNutricionalTut } from 'src/app/models/mpresc/producto-nutricional-tut.model';

@Component({
  selector: 'app-modal-producto-nutricional-tut-detalle',
  templateUrl: './modal-producto-nutricional-tut-detalle.component.html',
  styleUrls: ['./modal-producto-nutricional-tut-detalle.component.scss']
})
export class ModalProductoNutricionalTutDetalleComponent implements OnInit {
  @Input() public productoNutr: ProductoNutricionalTut;
  @Input() public tutela: TutelaApi;
  @Output() public showModalTutela = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalTutela.emit(this.tutela);
  }

}
