export class Tarifa {
  codigo_tarifa: string
  nombre_tarifa: string
  id_tipo_tarifa: number
  tipo_tarifa: number
  id_tarifa: number
  id_prestador: number
  autorizado: boolean
  activo: boolean
  valor_tarifa: number
  id_contrato: number
  id_tarifa_aprobada: number
  codigo_original: string
  codigo_servicio: string
  pbs: boolean
  id_clasificacion_servicio: number

  nombre_clasificacion: string
  nombre_codigo: string

}
export const getNombreCodigoTarifa = (tarifa : Tarifa) => {
  let nombre = tarifa.codigo_original
  if (!nombre && tarifa.codigo_servicio) {
    nombre = tarifa.codigo_servicio
  }
  if (!nombre) {
    nombre = tarifa.codigo_tarifa
  }
  return nombre
}

export const getNombreClasificacionTarifa = (tarifa : Tarifa) => {
    let clasificaciones = [ 'Servicios Salud','Medicamentos','Materiales e insumos',
      'Nutricionales','Servicios Complementarios']
    let nombre = ''
    if (tarifa.id_clasificacion_servicio) {
      nombre = clasificaciones[tarifa.id_clasificacion_servicio-1]
    }
    if (tarifa.tipo_tarifa) {
      nombre = clasificaciones[tarifa.tipo_tarifa-1]
    }
    return nombre
}
