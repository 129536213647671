import { API_REST } from "./../url.constants";
import { HttpClient } from "@angular/common/http";
import { NoDireccionamientosApi } from "./../models/containers/no-direccionamientos-api.model";
import { MensajesAnulacionApi } from "./../models/containers/mensajes-anulacion-api.model";
import { NoDireccionamientoAnularSalida } from "./../models/salida/no-direccionamiento-anular-salida.model";
import { NoDireccionamientoSalida } from "./../models/salida/no-direccionamiento-salida.model";
import { IdentificadoresNoDireccionamientoApi } from "./../models/containers/identificadores-no-direccionamiento-api.model";
import { Injectable } from "@angular/core";
import { NoDireccionamientoDataSalida } from "../models/salida/no-direccionamiento-data-salida.model";
import { NoDireccionamientoData } from "../models/msumin/no-direccionamiento-data.model";

@Injectable({
  providedIn: "root"
})
export class NoDireccionamientosService {
  baseUrl = API_REST + "no-direccionamiento/";

  constructor(private http: HttpClient) {}

  getByFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<NoDireccionamientosApi>(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}`
    );
  }

  getByPacienteAndFecha(
    nit: string,
    regimen: string,
    fecha: string,
    tipoDoc: string,
    numDoc: string
  ) {
    return this.http.get<NoDireccionamientosApi>(
      `${this.baseUrl}byPacienteFecha/${nit}/${regimen}/${fecha}/${tipoDoc}/${numDoc}`
    );
  }

  getPrescripcion(nit: string, regimen: string, prescripcion: string) {
    return this.http.get<NoDireccionamientosApi>(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${prescripcion}`
    );
  }

  enviar(nit: string, regimen: string, entrada: NoDireccionamientoSalida) {
    return this.http.post<IdentificadoresNoDireccionamientoApi>(
      `${this.baseUrl}enviar/${nit}/${regimen}`,
      entrada
    );
  }

  getByFechaFile(regimen: string, fechaInicio: string, fechaFinal: string) {
    return this.http.get(
      `${this.baseUrl}txt/${regimen}/${fechaInicio}/${fechaFinal}?format=txt`,
      { responseType: "blob" }
    );
  }

  enviarData(
    nit: string,
    regimen: string,
    entrada: NoDireccionamientoDataSalida
  ) {
    return this.http.post<NoDireccionamientoData>(
      `${this.baseUrl}procesar/${nit}/${regimen}`,
      entrada
    );
  }

  anular(
    nit: string,
    regimen: string,
    entrada: NoDireccionamientoAnularSalida
  ) {
    return this.http.post<MensajesAnulacionApi>(
      `${this.baseUrl}anular/${nit}/${regimen}`,
      entrada
    );
  }
}
