import { Component, OnInit } from "@angular/core";
import { TarifaMensual } from "src/app/models/tarifa-mensual.model";
import { Regimen } from "src/app/models/regimen.model";
import { ApiService } from "src/app/services/api.service";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";
import { NAME_APP } from "src/app/url.constants";
import { Mes } from "src/app/data/datos.constants";
import { Eps } from "src/app/models/eps.model";

@Component({
  selector: "app-main-tarifas-mensual",
  templateUrl: "./main-tarifas-mensual.component.html",
  styleUrls: ["./main-tarifas-mensual.component.scss"]
})
export class MainTarifasMensualComponent implements OnInit {
  primera: boolean = false;
  cargando: boolean = false;

  tarifas: TarifaMensual[] = [];

  regimen: Regimen = null;
  eps: Eps = null;

  meses = Object.values(Mes).filter(key => !isNaN(Number(Mes[key])));

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Consumos general`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  busqueda() {
    this.tarifas = [];
    this.cargando = true;
    this.apiService.tarifaMensualService
      .getTarifas(this.regimen.codigo)
      .subscribe(
        data => {
          this.cargando = false;
          this.primera = true;
          this.tarifas = [];
          if (data.tarifas) {
            this.tarifas = data.tarifas;
          }
        },
        error => {
          this.apiService.notifService.error("Error", error);
          console.error(error);
          this.primera = true;
          this.cargando = false;
        }
      );
  }
}
