import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { ReportesEntregaApi } from "../models/containers/reportes-entrega-api.model";
import { ReporteEntrega } from "../models/msumin/reporte-entrega.model";

@Injectable({
  providedIn: "root"
})
export class ReportesEntregasService {
  baseUrl = API_REST + "reporteEntrega/";

  constructor(private http: HttpClient) {}

  byPrescripcion(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get<ReportesEntregaApi>(
      `${this.baseUrl}byPrescripcion/false/${nit}/${regimen}/${numPrescripcion}?format=json`
    );
  }

  byTutela(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get<ReportesEntregaApi>(
      `${this.baseUrl}byPrescripcion/true/${nit}/${regimen}/${numPrescripcion}?format=json`
    );
  }

  byNumero(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get<ReportesEntregaApi>(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${numPrescripcion}?format=json`
    );
  }

  filtroReporteEntrega(
    reportes: ReporteEntrega[],
    TipoTec: string,
    ConTec: number,
    NoEntrega: number
  ): ReporteEntrega {
    let filtro = reportes.filter(
      item =>
        item.EstRepEntrega != 0 &&
        item.TipoTec == TipoTec &&
        item.ConTec == ConTec &&
        item.NoEntrega == NoEntrega
    );
    if (filtro.length>0) {
      return filtro[0];
    }
    return null;
  }

  byNumeroExcel(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${numPrescripcion}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  byFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<ReportesEntregaApi>(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}?format=json`
    );
  }

  byFechaExcel(nit: string, regimen: string, fecha: string) {
    return this.http.get(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  byFechaPaciente(
    nit: string,
    regimen: string,
    fecha: string,
    numId: string,
    tipoId: string
  ) {
    return this.http.get<ReportesEntregaApi>(
      `${this.baseUrl}byPacienteFecha/${nit}/${regimen}/${fecha}/${tipoId}/${numId}?format=json`
    );
  }

  byFechaPacienteExcel(
    nit: string,
    regimen: string,
    fecha: string,
    numId: string,
    tipoId: string
  ) {
    return this.http.get(
      `${this.baseUrl}byPacienteFecha/${nit}/${regimen}/${fecha}/${tipoId}/${numId}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
