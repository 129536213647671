import { ApiService } from "./../../../services/api.service";
import { ReporteEntrega } from "./../../../models/msumin/reporte-entrega.model";
import { Input, ViewChild, ElementRef, EventEmitter, Output } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";

@Component({
  selector: "app-modal-reportes-entregas-detalle",
  templateUrl: "./modal-reportes-entregas-detalle.component.html",
  styleUrls: ["./modal-reportes-entregas-detalle.component.scss"]
})
export class ModalReportesEntregasDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;
  @Output() public showModalReporteEntrega = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  reportes: ReporteEntrega[];
  cargando: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.reportes = [];
      this.cargando = true;
      if (this.tutela) {
        this.apiService.reportesEntregasService
          .byTutela(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.reportes) {
                this.reportes = data.reportes;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );
      } else {
        this.apiService.reportesEntregasService
          .byPrescripcion(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.reportes) {
                this.reportes = data.reportes;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );
      }
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleReporte(rep: ReporteEntrega) {
    this.showModalReporteEntrega.emit({ rep: rep, presc: this.prescripcion});
  }

}
