import { JwtResponse } from "src/app/auth/jwt-response";
import { NAME_APP } from "./../../url.constants";
import { ApiService } from "./../../services/api.service";
import { Eps } from "./../../models/eps.model";
import { Component, OnInit } from "@angular/core";

import * as moment from "moment";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-main-inicio",
  templateUrl: "./main-inicio.component.html",
  styleUrls: ["./main-inicio.component.scss"]
})
export class MainInicioComponent implements OnInit {
  now = moment();

  info: JwtResponse = null;

  mesActual = this.now.get("M");
  anioActual = this.now.get("y");

  epsSelect: string = null;

  constructor(
    private token: TokenStorageService,
    private apiService: ApiService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Inicio`);
    this.token.validate();
    this.info = this.token.getInfo();

    if (this.info) {
      this.epsSelect = this.token.getInfo().nit;
    }
  }

  isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
