import { Injectable } from '@angular/core';
import { API_REST } from '../url.constants';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AfiliadoService {
  baseUrl = API_REST + "afiliados/";

  constructor(private http: HttpClient) {}

  searchAfiliado(numId: string, tipoId: string) {
    return this.http.get(`${this.baseUrl}${tipoId}/${numId}`);
  }

}
