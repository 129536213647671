import { ApiService } from './services/api.service';
import { TokenInterceptor } from './core/interceptor';
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { NotificacionService } from './services/notificacion.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Select2Module } from 'ng2-select2';
import { AngularDualListBoxModule } from 'angular-dual-listbox';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { InicioComponent } from './components/inicio/inicio.component';
import { InicioSignInComponent } from './components/inicio-sign-in/inicio-sign-in.component';
import { CommonModule } from '@angular/common';
import { MainComponent } from './components/main/main.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { MainInicioComponent } from './components/main-inicio/main-inicio.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MainUsuarioComponent } from './components/main-usuario/main-usuario.component';
import { MainUsuarioAddComponent } from './components/main-usuario-add/main-usuario-add.component';
import { MainUsuarioEditComponent } from './components/main-usuario-edit/main-usuario-edit.component';
import { MainUsuariosComponent } from './components/main-usuarios/main-usuarios.component';
import { MainEpsEditComponent } from './components/main-eps-edit/main-eps-edit.component';
import { MainEpsAddComponent } from './components/main-eps-add/main-eps-add.component';
import { MainEpssComponent } from './components/main-epss/main-epss.component';
import { ModalEpsRegimenesComponent } from './components/modals/modal-eps-regimenes/modal-eps-regimenes.component';
import { MainTokenComponent } from './components/main-token/main-token.component';
import { ModalTokenEditComponent } from './components/modals/modal-token-edit/modal-token-edit.component';
import { MainPrescripcionesComponent } from './components/main-prescripciones/main-prescripciones.component';
import { InicioMainComponent } from './components/inicio-main/inicio-main.component';
import { ModalPrescripcionDetalleComponent } from './components/modals/modal-prescripcion-detalle/modal-prescripcion-detalle.component';
import { ModalMedicamentoDetalleComponent } from './components/modals/modal-medicamento-detalle/modal-medicamento-detalle.component';
import { ModalProcedimientoDetalleComponent } from './components/modals/modal-procedimiento-detalle/modal-procedimiento-detalle.component';
import { ModalServicioComplementarioDetalleComponent } from './components/modals/modal-servicio-complementario-detalle/modal-servicio-complementario-detalle.component';
import { ModalProductoNutricionalDetalleComponent } from './components/modals/modal-producto-nutricional-detalle/modal-producto-nutricional-detalle.component';
import { ModalDispositivoMedicoDetalleComponent } from './components/modals/modal-dispositivo-medico-detalle/modal-dispositivo-medico-detalle.component';
import { ModalDireccionamientosDetalleComponent } from './components/modals/modal-direccionamientos-detalle/modal-direccionamientos-detalle.component';
import { ModalDireccionamientoAddComponent } from './components/modals/modal-direccionamiento-add/modal-direccionamiento-add.component';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { ModalAuditarPrescripcionComponent } from './components/modals/modal-auditar-prescripcion/modal-auditar-prescripcion.component';
import { MainTutelasComponent } from './components/main-tutelas/main-tutelas.component';
import { ModalTutelaDetalleComponent } from './components/modals/modal-tutela-detalle/modal-tutela-detalle.component';
import { ModalMedicamentoTutDetalleComponent } from './components/modals/modal-medicamento-tut-detalle/modal-medicamento-tut-detalle.component';
import { ModalServicioComplementarioTutDetalleComponent } from './components/modals/modal-servicio-complementario-tut-detalle/modal-servicio-complementario-tut-detalle.component';
import { ModalDispositivoMedicoTutDetalleComponent } from './components/modals/modal-dispositivo-medico-tut-detalle/modal-dispositivo-medico-tut-detalle.component';
import { ModalProcedimientoTutDetalleComponent } from './components/modals/modal-procedimiento-tut-detalle/modal-procedimiento-tut-detalle.component';
import { ModalProductoNutricionalTutDetalleComponent } from './components/modals/modal-producto-nutricional-tut-detalle/modal-producto-nutricional-tut-detalle.component';
import { ModalNoDireccionamientosDetalleComponent } from './components/modals/modal-no-direccionamientos-detalle/modal-no-direccionamientos-detalle.component';
import { ModalNoDireccionamientoAddComponent } from './components/modals/modal-no-direccionamiento-add/modal-no-direccionamiento-add.component';
import { MainReportesEntregasComponent } from './components/main-reportes-entregas/main-reportes-entregas.component';
import { ModalReportesEntregasDetalleComponent } from './components/modals/modal-reportes-entregas-detalle/modal-reportes-entregas-detalle.component';
import { ModalReporteEntregaDetalleComponent } from './components/modals/modal-reporte-entrega-detalle/modal-reporte-entrega-detalle.component';
import { ModalSuministrosDetalleComponent } from './components/modals/modal-suministros-detalle/modal-suministros-detalle.component';
import { ModalSuministroDetalleComponent } from './components/modals/modal-suministro-detalle/modal-suministro-detalle.component';
import { ModalDatosFacturadosDetalleComponent } from './components/modals/modal-datos-facturados-detalle/modal-datos-facturados-detalle.component';
import { ModalFacturacionDetalleComponent } from './components/modals/modal-facturacion-detalle/modal-facturacion-detalle.component';
import { ModalFacturacionesDetalleComponent } from './components/modals/modal-facturaciones-detalle/modal-facturaciones-detalle.component';
import { ModalAuditoriaDetalleComponent } from './components/modals/modal-auditoria-detalle/modal-auditoria-detalle.component';
import { MainReporteDireccionamientosComponent } from './components/main-reporte-direccionamientos/main-reporte-direccionamientos.component';
import { MainSuministrosComponent } from './components/main-suministros/main-suministros.component';
import { MainInformeAutorizacionesComponent } from './components/main-informe-autorizaciones/main-informe-autorizaciones.component';
import { ModalBuscarTarifasComponent } from './components/modals/modal-buscar-tarifas/modal-buscar-tarifas.component';
import { MainTarifasMensualComponent } from './components/main-tarifas-mensual/main-tarifas-mensual.component';
import { MainTarifasMensualInformeComponent } from './components/main-tarifas-mensual-informe/main-tarifas-mensual-informe.component';
import { MainNoDireccionamientosComponent } from './components/main-no-direccionamientos/main-no-direccionamientos.component';
import { ModalNoDireccionamientoDetalleComponent } from './components/modals/modal-no-direccionamiento-detalle/modal-no-direccionamiento-detalle.component';
import { MainNoDireccionamientosReporteComponent } from './components/main-no-direccionamientos-reporte/main-no-direccionamientos-reporte.component';
import { MainValidadorPrescripcionesComponent } from './components/main-validador-prescripciones/main-validador-prescripciones.component';
import { ModalBuscarHomologosComponent } from './components/modals/modal-buscar-homologos/modal-buscar-homologos.component';
import { MainReporteDinamicoComponent } from './components/main-reporte-dinamico/main-reporte-dinamico.component';
import { MainInformeRecobrosComponent } from './components/main-informe-recobros/main-informe-recobros.component';
import { ModalBuscarTutelasComponent } from './components/modal-buscar-tutelas/modal-buscar-tutelas.component';
import { MainCargueCodigosComponent } from './components/main-cargue-codigos/main-cargue-codigos.component';
import { MainTareasProgramadasComponent } from './components/main-tareas-programadas/main-tareas-programadas.component';
import { ModalRegistrarTareaComponent } from './components/modals/modal-registrar-tarea/modal-registrar-tarea.component';
import { ModalFacturaRipsComponent } from './components/modals/modal-factura-rips/modal-factura-rips.component';


@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    InicioSignInComponent,
    MainComponent,
    MainHeaderComponent,
    MainFooterComponent,
    MainInicioComponent,
    MainMenuComponent,
    MainUsuarioComponent,
    MainUsuarioAddComponent,
    MainUsuarioEditComponent,
    MainUsuariosComponent,
    MainEpsEditComponent,
    MainEpsAddComponent,
    MainEpssComponent,
    ModalEpsRegimenesComponent,
    MainTokenComponent,
    ModalTokenEditComponent,
    MainPrescripcionesComponent,
    InicioMainComponent,
    ModalPrescripcionDetalleComponent,
    ModalMedicamentoDetalleComponent,
    ModalProcedimientoDetalleComponent,
    ModalServicioComplementarioDetalleComponent,
    ModalProductoNutricionalDetalleComponent,
    ModalDispositivoMedicoDetalleComponent,
    ModalDireccionamientosDetalleComponent,
    ModalDireccionamientoAddComponent,
    NotificationListComponent,
    ModalAuditarPrescripcionComponent,
    MainTutelasComponent,
    ModalTutelaDetalleComponent,
    ModalMedicamentoTutDetalleComponent,
    ModalServicioComplementarioTutDetalleComponent,
    ModalDispositivoMedicoTutDetalleComponent,
    ModalProcedimientoTutDetalleComponent,
    ModalProductoNutricionalTutDetalleComponent,
    ModalNoDireccionamientosDetalleComponent,
    ModalNoDireccionamientoAddComponent,
    MainReportesEntregasComponent,
    ModalReportesEntregasDetalleComponent,
    ModalReporteEntregaDetalleComponent,
    ModalSuministrosDetalleComponent,
    ModalSuministroDetalleComponent,
    ModalDatosFacturadosDetalleComponent,
    ModalFacturacionDetalleComponent,
    ModalFacturacionesDetalleComponent,
    ModalAuditoriaDetalleComponent,
    MainReporteDireccionamientosComponent,
    MainSuministrosComponent,
    MainInformeAutorizacionesComponent,
    ModalBuscarTarifasComponent,
    MainTarifasMensualComponent,
    MainTarifasMensualInformeComponent,
    MainNoDireccionamientosComponent,
    ModalNoDireccionamientoDetalleComponent,
    MainNoDireccionamientosReporteComponent,
    MainValidadorPrescripcionesComponent,
    ModalBuscarHomologosComponent,
    MainReporteDinamicoComponent,
    MainInformeRecobrosComponent,
    ModalBuscarTutelasComponent,
    MainCargueCodigosComponent,
    MainTareasProgramadasComponent,
    ModalRegistrarTareaComponent,
    ModalFacturaRipsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    Select2Module,
    AngularDualListBoxModule
  ],
  providers: [NotificacionService, ApiService, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
