import { CupsApi } from './../models/containers/cups-api.model';
import { HttpClient } from '@angular/common/http';
import { API_REST } from './../url.constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CupsService {
  baseUrl = API_REST + "cups/";

  constructor(private http: HttpClient) {}

  getCupsByNombre(nombre: string) {
    return this.http.get<CupsApi>(`${this.baseUrl}${nombre}`);
  }
}
