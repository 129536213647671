import { Component, OnInit, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Eps } from 'src/app/models/eps.model';
import { Regimen } from 'src/app/models/regimen.model';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { Proveedor } from 'src/app/models/proveedor.model';
import { DciApi } from 'src/app/models/containers/dci-api.model';
import { Facturacion } from 'src/app/models/mfactu/facturacion-model';

@Component({
  selector: 'app-modal-buscar-homologos',
  templateUrl: './modal-buscar-homologos.component.html',
  styleUrls: ['./modal-buscar-homologos.component.scss']
})
export class ModalBuscarHomologosComponent implements OnInit {

  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public facturacion: Facturacion;
  @Input() public prescripcion: string;
  @Input() public informacion: any;
  @Output() public showModalFacturacion = new EventEmitter();

  tarifas: any[] = [];
  cargandoTarifas: boolean = false;
  busquedaTarifas: boolean = false;

  tarifaForm: FormGroup;

  @ViewChild("modalCloseHomologo", { static: false }) modalCloseHomologo: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.tarifaForm = this.fb.group({
        nombre: new FormControl('')
      });
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalCloseHomologo.nativeElement.click();
    this.showModalFacturacion.emit({ fact: this.facturacion, presc: this.prescripcion, 
      informacion: this.informacion, homologo: null});
  }

  public seleccionarHomologo(dci: DciApi) {
    this.busquedaTarifas=false;
    this.modalCloseHomologo.nativeElement.click();
    this.showModalFacturacion.emit({ fact: this.facturacion, presc: this.prescripcion, 
      informacion: this.informacion, homologo: dci});
  }

  buscarTarifas() {
    this.tarifas = [];
    this.cargandoTarifas = true;
    this.busquedaTarifas = true;
    let {nombre } = this.tarifaForm.value;
    this.apiService.codigosMipresService
      .getDciByNombre(nombre)
      .subscribe(data => {
        this.tarifas = data;
        this.cargandoTarifas = false;
      }, error => {
        this.cargandoTarifas = false;
        console.log(error);
      });
  }
  
  onSubmit() {
  }

}
