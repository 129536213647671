export class UsuarioSalida {
  nombres: string;
  apellidos: string;
  email: string;
  identificacion: string;
  password: string;
  enable: boolean;
  tipoId: string;
  nit: string;
  rol: string;
  auditor: boolean;
}
