import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { ServicioComplementario } from "./../../../models/mpresc/servicio-complementario.model";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from "@angular/core";

@Component({
  selector: "app-modal-servicio-complementario-detalle",
  templateUrl: "./modal-servicio-complementario-detalle.component.html",
  styleUrls: ["./modal-servicio-complementario-detalle.component.scss"]
})
export class ModalServicioComplementarioDetalleComponent implements OnInit {
  @Input() public servicioCompl: ServicioComplementario;
  @Input() public prescripcion: PrescripcionApi;
  @Output() public showModalPrescripcion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalPrescripcion.emit(this.prescripcion);
  }
}
