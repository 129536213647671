import { EpsSalida } from "./../models/salida/eps-salida.model";
import { EpsApi } from "./../models/containers/eps-api.model";
import { EpssApi } from "./../models/containers/epss-api.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { RegimenEpsSalida } from "../models/salida/regimen-eps-salida.model";

@Injectable({
  providedIn: "root"
})
export class EpsService {
  baseUrl = API_REST + "eps/";

  constructor(private http: HttpClient) {}

  getListaEps() {
    return this.http.get<EpssApi>(`${this.baseUrl}`);
  }

  getEps() {
    return this.http.get<EpsApi>(`${this.baseUrl}`);
  }

  saveEps(eps: EpsSalida) {
    return this.http.post<EpsApi>(`${this.baseUrl}`, eps);
  }

  saveRegimen(regimen: RegimenEpsSalida) {
    return this.http.post<EpsApi>(`${this.baseUrl}`, regimen);
  }

  deleteRegimen(codigo: string) {
    return this.http.delete<EpsApi>(`${this.baseUrl}${codigo}`);
  }
}
