import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { MunicipiosApi } from "../models/containers/municipios-api.model";

@Injectable({
  providedIn: "root"
})
export class MunicipiosService {
  baseUrl = API_REST + "municipio/";

  constructor(private http: HttpClient) {}

  getMunicipios() {
    return this.http.get<MunicipiosApi>(`${this.baseUrl}`);
  }

  getDynamicList() {
    return Observable.create(obs => {
      let list = [];
      this.getMunicipios().subscribe(data => {
        data.municipios.forEach(element => {
          list.push({
            id: element.codigoDptoMpio,
            text: element.nombreMunicipio
          });
        });
        obs.next(list);
        obs.complete();
      });
    });
  }
}
