import { CumApi } from './../models/containers/cum-api.model';
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DciApi } from '../models/containers/dci-api.model';
import { ComparadorAdministrativo } from '../models/containers/comparador-administrativo.model';
import { UnidadDispensacion } from '../models/containers/unidad-dispensacion.model';
import { CausaNoEntrega } from '../models/causa-no-entrega.model';
import { HttpClient } from '@angular/common/http';
import { API_REST } from '../url.constants';
import { Servicio } from '../models/servicio.model';

@Injectable({
  providedIn: 'root'
})
export class CodigosMipresService {

  baseUrl = API_REST + "codigos/";

  constructor(private http: HttpClient) {}

  getCausasNoEntregaPorTipo(tipo: string) {
    return this.http.get<CausaNoEntrega[]>(`${this.baseUrl}causas/${tipo}`);
  }

  getServicios(tipo: string) {
    return this.http.get<Servicio[]>(`${this.baseUrl}servicios/${tipo}`);
  }

  getServiciosDynamicList(tipo: string, actual: number = null) {
    return Observable.create(obs => {
      let list = [];
      this.getServicios(tipo).subscribe(data => {
        data.forEach(element => {
          list.push({
            id: element.codigo,
            text: `(${element.codigo}) ${element.nombre}`,
            selected: actual && actual == element.codigo ? true : false
          });
        });
        obs.next(list);
        obs.complete();
      });
    });
  }

  getUnidadesDispensacion() {
    return this.http.get<UnidadDispensacion[]>(`${this.baseUrl}unidadesDisp`);
  }

  getUnidadesDispensacionDynamicList(item: any = null) {
    return Observable.create(obs => {
      let list = [];
      this.getUnidadesDispensacion().subscribe(data => {
        data.forEach(element => {
          list.push({
            id: element.codigo,
            text: element.descripcion,
            selected: item != null && item == element.codigo ? true: false
          });
        });
        obs.next(list);
        obs.complete();
      });
    });
  }

  getComparadoresAdministrativo() {
    return this.http.get<ComparadorAdministrativo[]>(`${this.baseUrl}comparadoresAdm`);
  }

  getDci() {
    return this.http.get<DciApi[]>(`${this.baseUrl}dci`);
  }

  getDciByNombre(nombre: string) {
    return this.http.get<DciApi[]>(`${this.baseUrl}dci/${nombre}`);
  }

  getDciDynamicList() {
    return Observable.create(obs => {
      let list = [];
      this.getDci().subscribe(data => {
        data.forEach(element => {
          list.push({
            id: element.codigo,
            text: element.descripcion
          });
        });
        obs.next(list);
        obs.complete();
      });
    });
  }

  saveCums(file: File): Observable<HttpEvent<{}>> {

    const formdata: FormData = new FormData();
    formdata.append('file', file);

    const req = new HttpRequest('POST', `${API_REST}codigos-cums/cargaMasiva`, formdata, {
      reportProgress: true
    });

    return this.http.request(req);
  }

  getCum(nombre = '', codigo = '', principio = '') {
    return this.http.get<CumApi[]>(`${this.baseUrl}cum?nombre=${nombre}&codigo=${codigo}&principio=${principio}`);
  }
}
