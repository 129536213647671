import { ApiService } from './../../../services/api.service';
import { NoDireccionamientoSalida } from './../../../models/salida/no-direccionamiento-salida.model';
import { DireccionamientoSalida } from './../../../models/salida/direccionamiento-salida.model';
import { AuditoriaSalida } from './../../../models/salida/auditoria-salida.model';
import { Usuario } from 'src/app/models/usuario.model';
import { Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { MedicamentoTut } from './../../../models/mpresc/medicamento-tut.model';
import { DispositivoMedicoTut } from './../../../models/mpresc/dispositivo-medico-tut.model';
import { Component, OnInit, Input } from '@angular/core';
import { ServicioComplementarioTut } from 'src/app/models/mpresc/servicio-complemento-tut.model';
import { ProductoNutricionalTut } from 'src/app/models/mpresc/producto-nutricional-tut.model';
import { ProcedimientoTut } from 'src/app/models/mpresc/procedimiento-tut.model';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Eps } from 'src/app/models/eps.model';
import { Regimen } from 'src/app/models/regimen.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-tutela-detalle',
  templateUrl: './modal-tutela-detalle.component.html',
  styleUrls: ['./modal-tutela-detalle.component.scss']
})
export class ModalTutelaDetalleComponent implements OnInit {
  @Input() public tutela: TutelaApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;

  @Output() public showModalMedicamento = new EventEmitter();
  @Output() public showModalServicio = new EventEmitter();
  @Output() public showModalProcedimiento = new EventEmitter();
  @Output() public showModalProducto = new EventEmitter();
  @Output() public showModalDispositivo = new EventEmitter();

  @Output() public showModalDireccionamientoTutAdd = new EventEmitter();
  @Output() public showModalNoDireccionamientoTutAdd = new EventEmitter();
  @Output() public showModalAuditoriaTut = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  filtrado: string;

  estadoAuditoria: string = null;

  esAuditable: boolean = true;

  constructor(private token: TokenStorageService, private apiService: ApiService) {}

  ngOnInit() {
    this.token.validate();
    if (this.tutela) {
      this.esAuditable = !this.tutela.auditoria.procesada && this.isValido('ROLE_AUDITOR');
      if (this.tutela.medicamentos.length > 0) {
        this.filtrado = "m";
      } else if (this.tutela.procedimientos.length > 0) {
        this.filtrado = "p";
      } else if (this.tutela.productosnutricionales.length > 0) {
        this.filtrado = "n";
      } else if (this.tutela.serviciosComplementarios.length > 0) {
        this.filtrado = "s";
      } else if (this.tutela.dispositivos.length > 0) {
        this.filtrado = "d";
      }
      if (this.tutela.auditoria.procesada) {
        this.estadoAuditoria = "text-success";
      } else {
        this.estadoAuditoria = "text-warning";
      }
      if (this.esAuditable && this.tutela.tutela.rel.rel.identificacion!=null) {
        this.esAuditable = this.tutela.tutela.rel.rel.estado;
      }
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleMedicamento(med: MedicamentoTut) {
    this.showModalMedicamento.emit({ med: med, tut: this.tutela });
  }

  detalleServicioCompl(serv: ServicioComplementarioTut) {
    this.showModalServicio.emit({ serv: serv, tut: this.tutela });
  }

  detalleProcedimiento(proc: ProcedimientoTut) {
    this.showModalProcedimiento.emit({ proc: proc, tut: this.tutela });
  }

  detalleProductoNutri(prod: ProductoNutricionalTut) {
    this.showModalProducto.emit({ prod: prod, tut: this.tutela });
  }

  detalleDispositivoMedic(disp: DispositivoMedicoTut) {
    this.showModalDispositivo.emit({ disp: disp, tut: this.tutela });
  }

  auditar() {
    let salida = new AuditoriaSalida();

    salida.numPrescripcion = this.tutela.tutela.NoTutela;
    salida.tutela = true;
    salida.numIdPaciente = this.tutela.tutela.NroIDPaciente;
    salida.tipoIdPaciente = this.tutela.tutela.TipoIDPaciente;

    this.showModalAuditoriaTut.emit({
      salida,
      tut: this.tutela
    })

  }

  direccionamientoMedAdd(med: MedicamentoTut) {
    let salida = new DireccionamientoSalida();
    salida.ConTec = med.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "M";
    this.showModalDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela,
      medicamentoTut: med
    });
  }

  noDireccionamientoMedAdd(med: MedicamentoTut) {
    let salida = new DireccionamientoSalida();
    salida.ConTec = med.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "M";
    this.showModalNoDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela,
      medicamentoTut: med
    });
  }

  direccionamientoProcAdd(proc: ProcedimientoTut) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = proc.ConOrden;
    salida.CodSerTecAEntregar = proc.CodCUPS;
    salida.DescSerTec = proc.rel.descCodCUPS;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "P";
    this.showModalDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  noDireccionamientoProcAdd(proc: ProcedimientoTut) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = proc.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.TipoTec = "P";
    this.showModalNoDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  direccionamientoProdAdd(prod: ProductoNutricionalTut) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = prod.ConOrden;
    salida.CodSerTecAEntregar = prod.DescProdNutr+"";
    salida.DescSerTec = prod.rel.descDescProdNutr;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "N";
    this.showModalDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  noDireccionamientoProdAdd(prod: ProductoNutricionalTut) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = prod.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.TipoTec = "N";
    this.showModalNoDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  direccionamientoServAdd(serv: ServicioComplementarioTut) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = serv.ConOrden;
    salida.CodSerTecAEntregar = serv.CodSerComp;
    salida.DescSerTec = serv.rel.descCodSerComp;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "S";
    this.showModalDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  noDireccionamientoServAdd(serv: ServicioComplementarioTut) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = serv.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.TipoTec = "S";
    this.showModalNoDireccionamientoTutAdd.emit({
      salida,
      tut: this.tutela
    });
  }

  direccionamientoDispAdd(disp: DispositivoMedicoTut) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = disp.ConOrden;
    salida.CodSerTecAEntregar = disp.CodDisp;
    salida.DescSerTec = disp.rel.descCodDisp;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.DiagPrinc = this.tutela.tutela.CodDxPpal;
    salida.TipoTec = "D";
    this.showModalDireccionamientoTutAdd.emit({
      salida: salida,
      tut: this.tutela
    });
  }

  noDireccionamientoDispAdd(disp: DispositivoMedicoTut) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = disp.ConOrden;

    salida.NoIDPaciente = this.tutela.tutela.NroIDPaciente;
    salida.TipoIDPaciente = this.tutela.tutela.TipoIDPaciente;
    salida.NoPrescripcion = this.tutela.tutela.NoTutela;
    salida.TipoTec = "D";
    this.showModalNoDireccionamientoTutAdd.emit({
      salida: salida,
      tut: this.tutela
    });
  }

  tutelaPdf() {
    Swal.fire({
      title: 'Generando archivo ... ',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    this.apiService.tutelasService.getPorNumeroPdf(this.eps.identificacion, this.regimen.codigo, this.tutela.tutela.NoTutela).subscribe(
      response => {
        Swal.close();
        this.apiService.utilService.downloadFile(response, 'Tutela - '+ this.tutela.tutela.NoTutela, 'pdf');
      }, error => {
        Swal.close();
        console.log(error);
        this.apiService.notifService.error('Error', error);
      }
    )
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
