import { Eps } from "./../../models/eps.model";
import { NAME_APP } from "./../../url.constants";
import { Title } from "@angular/platform-browser";
import { ApiService } from "./../../services/api.service";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Regimen } from "src/app/models/regimen.model";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import Swal from "sweetalert2";

@Component({
  selector: "app-main-reporte-direccionamientos",
  templateUrl: "./main-reporte-direccionamientos.component.html",
  styleUrls: ["./main-reporte-direccionamientos.component.scss"]
})
export class MainReporteDireccionamientosComponent implements OnInit {
  now = moment();
  momento = moment;
  filtrado: string = "f";
  maxDias: number = 14;
  tipoBusqueda: number = 1;
  
  tipos = TIPOS_DOCUMENTOS;

  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFin: string = this.fechaInicio;
  tipoReporte: number = 0;
  identificacion: string = "";
  tipoId: string = this.tipos[0].value;
  numero: string = "";
  estados: string = "1-1-1";

  estado_anul: boolean = true;
  estado_acti: boolean = true;
  estado_proc: boolean = true;

  eps: Eps = null;
  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Reporte general`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  cambiarFiltro(f: string) {
    this.filtrado = f;
    this.identificacion = "";
    this.numero = "";
  }

  busqueda() {
    Swal.fire({
      title: "Generando archivo ... ",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    let tipo = parseInt(this.tipoReporte + "");

    let nombreReporte =
      (tipo < 2 ? "DIRS" :
      tipo < 4 ? "DETALLE" : tipo == 4 ? "NODIRS" : tipo == 5 ? "ST015" : tipo == 6 ? "T760" : "DETALLADO") +
      "-" + ([1,3,8].includes(tipo) ? "tut-" : [0,2,7].includes(tipo) ? "presc-":'')+
      this.regimen.codigo;

    switch (this.filtrado) {
      case "f":
        nombreReporte += "-" + this.fechaInicio + "_" + this.fechaFin;
        break;
      case "p":
        nombreReporte += "-" + this.identificacion;
        break;
      case "n":
        nombreReporte += "-" + this.numero;
        break;
    }

    this.estados =
      (this.estado_anul ? 1 : 0) +
      "-" +
      (this.estado_proc ? 1 : 0) +
      "-" +
      (this.estado_acti ? 1 : 0);
    if (this.identificacion.trim().length == 0) {
      this.identificacion = "null";
    }
    if (this.numero.trim().length == 0) {
      this.numero = "null";
    }
    switch (tipo) {
      case 0:
        this.apiService.reporteDireccionamientosService
          .xlsxDirsPrescripciones(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero,
            this.estados
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 1:
        this.apiService.reporteDireccionamientosService
          .xlsxDirsTutelas(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero,
            this.estados
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 2:
        this.apiService.auditoriaService
          .xlsxReporteDetalles(
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.numero,
            false
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 3:
        this.apiService.auditoriaService
          .xlsxReporteDetalles(
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.numero,
            true
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 4:
        this.apiService.reporteDireccionamientosService
          .xlsxNoDirsPrescripciones(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero,
            this.estados
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 5:
        this.apiService.reporteDireccionamientosService
          .xlsxInformeST015(
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 6:
        this.apiService.reporteDireccionamientosService
          .xlsxInformeT760(
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
        case 7:
          this.apiService.reporteDireccionamientosService
            .detalladoPrescripcionXlsx(
              'P',
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaInicio,
              this.fechaFin,
              this.estados
            )
            .subscribe(
              response => {
                this.apiService.utilService.downloadFile(
                  response,
                  nombreReporte,
                  "xlsx"
                );
              },
              error => {
                console.log(error);
                this.apiService.notifService.error("Error", error);
                Swal.close();
              }
            );
          break;
        case 8:
            this.apiService.reporteDireccionamientosService
              .detalladoTutelasXlsx(
                'P',
                this.eps.identificacion,
                this.regimen.codigo,
                this.fechaInicio,
                this.fechaFin,
                this.estados
              )
              .subscribe(
                response => {
                  this.apiService.utilService.downloadFile(
                    response,
                    nombreReporte,
                    "xlsx"
                  );
                },
                error => {
                  console.log(error);
                  this.apiService.notifService.error("Error", error);
                  Swal.close();
                }
              );
            break;
      default:
        Swal.close();
        break;
    }
  }
}
