import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { ValidadorPrescripcionesSalida } from "../models/salida/validador-prescripciones-salida.model";
import { API_REST } from "../url.constants";

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
};
@Injectable({
  providedIn: "root"
})
export class ValidadorPrescripcionesService {
  baseUrl = API_REST + "validador-prescripciones";

  constructor(private http: HttpClient) {}

  validarPrescripciones(proveedor: ValidadorPrescripcionesSalida) {
    const formdata: FormData = new FormData();
    if (proveedor.file) formdata.append("file", proveedor.file);
    formdata.append("nit", proveedor.nit);
    formdata.append("regimen", proveedor.regimen);
    formdata.append("valFacturacion", "" + proveedor.valFacturacion);
    formdata.append("valSuministro", "" + proveedor.valSuministro);

    return this.http.post(this.baseUrl, formdata, { responseType: "blob" });
    // return this.http.post(this.baseUrl, formdata, {
    //   responseType: "blob",
    //   headers: new HttpHeaders({ "Content-Type": "multipart/form-data"})
    // });
    //const req = new HttpRequest('POST', this.baseUrl, formdata, {responseType: "blob"});
    //return this.http.request(req);
  }
}
