import { TutelaAfiliado } from './../../models/tutela-afiliado.model';
import { ApiService } from './../../services/api.service';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Proveedor } from './../../models/proveedor.model';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { Regimen } from './../../models/regimen.model';
import { Eps } from './../../models/eps.model';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-modal-buscar-tutelas',
  templateUrl: './modal-buscar-tutelas.component.html',
  styleUrls: ['./modal-buscar-tutelas.component.scss']
})
export class ModalBuscarTutelasComponent implements OnInit {

  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public proveedor: Proveedor;
  @Input() public direccionamiento: any;
  @Output() public showModalDireccionamientoAdd = new EventEmitter();

  tarifas: any[] = [];
  cargandoTarifas: boolean = false;
  busquedaTarifas: boolean = false;

  tarifaForm: FormGroup;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.tarifaForm = this.fb.group({
        nombre: new FormControl('')
      });
      this.buscarTutelas()
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    let info = this.direccionamiento;
    info['proveedor'] = this.proveedor;
    this.showModalDireccionamientoAdd.emit(info);
  }

  public seleccionarTutela(tutela: TutelaAfiliado) {
    this.busquedaTarifas=false;
    this.modalClose.nativeElement.click();
    let info = this.direccionamiento;
    info['tutelaAfiliado'] = tutela;
    info['proveedor'] = this.proveedor;
    this.showModalDireccionamientoAdd.emit(info);
  }

  buscarTutelas() {
    this.tarifas = [];
    this.cargandoTarifas = true;
    this.busquedaTarifas = true;
    let {nombre } = this.tarifaForm.value;

    let idAfiliado = null
    try {
      if (this.tutela) {
        idAfiliado = this.tutela.tutela.rel.rel.idAfiliado
      }
      if (this.prescripcion) {
        idAfiliado = this.prescripcion.prescripcion.rel.rel.idAfiliado
      }
    } catch (error) {      
    }

    this.apiService.tutelasAfiliadoService
      .getByIdAfiliado(idAfiliado, nombre)
      .subscribe(data => {
        this.tarifas = data.tutelas;
        this.cargandoTarifas = false;
      }, error => {
        this.cargandoTarifas = false;
        console.log(error);
      });
  }

}
