import { AuditoriaRel } from "./../../../models/mpresc/auditoria-rel.model";
import { Output, EventEmitter } from "@angular/core";
import { AuditoriaSalida } from "./../../../models/salida/auditoria-salida.model";
import { AuditoriaOrdenRel } from "./../../../models/mpresc/auditoria-orden-rel.model";
import { AuditoriaOrdenSalida } from "./../../../models/salida/auditoria-orden-salida.model";
import { ApiService } from "./../../../services/api.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";
import { TutelaApi } from "src/app/models/mpresc/tutela-api.model";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { Medicamento } from 'src/app/models/mpresc/medicamento.model';

@Component({
  selector: "app-modal-auditoria-detalle",
  templateUrl: "./modal-auditoria-detalle.component.html",
  styleUrls: ["./modal-auditoria-detalle.component.scss"]
})
export class ModalAuditoriaDetalleComponent implements OnInit {
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Output() public actualizarPrescripcion = new EventEmitter();
  @Output() public actualizarTutela = new EventEmitter();

  auditoriaForm: FormGroup = null;

  ordenes: AuditoriaOrdenSalida[] = [];

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    this.auditoriaForm = this.fb.group({
      descripcion: ['']
    });
    if (this.prescripcion) {
      this.auditoriaForm = this.fb.group({
        descripcion: [this.prescripcion.auditoria.descripcion]
      });
      this.cargarOrdenes(this.prescripcion.auditoria.ordenes);
      this.cargarFaltantesPrescripciones();
    }
    if (this.tutela) {
      this.auditoriaForm = this.fb.group({
        descripcion: [this.tutela.auditoria.descripcion]
      });
      this.cargarOrdenes(this.tutela.auditoria.ordenes);
      this.cargarFaltantesTutelas();
    }
  }

  cargarOrdenes(servicios: AuditoriaOrdenRel[]) {
    this.ordenes = new Array();

    servicios.forEach(element => {
      this.ordenes.push(
        new AuditoriaOrdenSalida(
          element.orden,
          element.tipoTecnologia,
          element.aprobada,
          element.descripcion,
          element.totalEntregas,
          element.tipoPrestacion
        )
      );
    });
  }

  cargarFaltantesPrescripciones() {
    let m = this.prescripcion.medicamentos;
    let p = this.prescripcion.procedimientos;
    let n = this.prescripcion.productosnutricionales;
    let s = this.prescripcion.serviciosComplementarios;
    let d = this.prescripcion.dispositivos;

    if (m) {
      this.agregar(m, 'M');
    }

    if (p) {
      this.agregar(p, 'P');
    }

    if (n) {
      this.agregar(n, 'N');
    }

    if (s) {
      this.agregar(s, 'S');
    }

    if (d) {
      this.agregar(d, 'D');
    }

  }

  cargarFaltantesTutelas() {
    let m = this.tutela.medicamentos;
    let p = this.tutela.procedimientos;
    let n = this.tutela.productosnutricionales;
    let s = this.tutela.serviciosComplementarios;
    let d = this.tutela.dispositivos;

    if (m) {
      this.agregar(m, 'M');
    }

    if (p) {
      this.agregar(p, 'P');
    }

    if (n) {
      this.agregar(n, 'N');
    }

    if (s) {
      this.agregar(s, 'S');
    }

    if (d) {
      this.agregar(d, 'D');
    }
  }

  agregar(items: any, tipoTec: string) {
    items.forEach(detalle => {
      let exists = false;
      this.ordenes.forEach(orden => {
        if (orden.conOrden == detalle.ConOrden
          && orden.tipoTec == tipoTec) {
            exists = true;
        }
      });
      if (!exists) {
        this.ordenes.push(
          new AuditoriaOrdenSalida(
            detalle.ConOrden,
            tipoTec,
            false,
            '',
            0,
            detalle.TipoPrest,
            true
          )
        );
      }
    });
  }

  onSubmit() {
    let { descripcion } = Object.assign({}, this.auditoriaForm.value);

    let auditoriaEdit = new AuditoriaSalida();

    if (this.prescripcion) {
      auditoriaEdit.tutela=false;
      auditoriaEdit.numPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
      auditoriaEdit.numIdPaciente = this.prescripcion.prescripcion.NroIDPaciente;
      auditoriaEdit.tipoIdPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    } else {
      auditoriaEdit.tutela=true;
      auditoriaEdit.numPrescripcion = this.tutela.tutela.NoTutela;
      auditoriaEdit.numIdPaciente = this.tutela.tutela.NroIDPaciente;
      auditoriaEdit.tipoIdPaciente = this.tutela.tutela.TipoIDPaciente;
    }

    auditoriaEdit.descripcion = descripcion;
    auditoriaEdit.ordenes = this.ordenes;

    this.apiService.auditoriaService
      .actualizarAuditoria(auditoriaEdit)
      .subscribe(
        data => {
          this.modalClose.nativeElement.click();
          if (auditoriaEdit.tutela) {
            this.tutela.auditoria = data.auditoria;
            this.updateTutAuditoria();
            this.actualizarTutela.emit({ tut: this.tutela });
          } else {
            this.prescripcion.auditoria = data.auditoria;
            this.updatePrescAuditoria();
            this.actualizarPrescripcion.emit({ presc: this.prescripcion });
          }
        },
        error => {
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  updatePrescAuditoria() {
    this.prescripcion.auditoria.ordenes.forEach(orden => {
      switch (orden.tipoTecnologia) {
        case "M":
          this.prescripcion.medicamentos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "P":
          this.prescripcion.procedimientos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "N":
          this.prescripcion.productosnutricionales.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "D":
          this.prescripcion.dispositivos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "S":
          this.prescripcion.serviciosComplementarios.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
      }
    });
  }

  updateTutAuditoria() {
    this.tutela.auditoria.ordenes.forEach(orden => {
      switch (orden.tipoTecnologia) {
        case "M":
          this.tutela.medicamentos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "P":
          this.tutela.procedimientos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "N":
          this.tutela.productosnutricionales.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "D":
          this.tutela.dispositivos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "S":
          this.tutela.serviciosComplementarios.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
      }
    });
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }
}
