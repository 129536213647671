import { TIPOS_DOCUMENTOS } from './../../data/datos.constants';
import { Regimen } from './../../models/regimen.model';
import { ApiService } from './../../services/api.service';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Title } from '@angular/platform-browser';
import { Eps } from './../../models/eps.model';
import { NAME_APP } from './../../url.constants';
import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-main-informe-recobros',
  templateUrl: './main-informe-recobros.component.html',
  styleUrls: ['./main-informe-recobros.component.scss']
})
export class MainInformeRecobrosComponent implements OnInit {
  
  tipoBusqueda = "p";

  now = moment();
  momento = moment;
  filtrado: string = "f";
  maxDias: number = 9;
  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFin: string = this.fechaInicio;
  tipoDocumento: string = "CC";
  documento: string = "";
  prescripcion: string = "";
  tipo: string = "S";
  tipoEnlace: string = "";

  cargando: boolean = false;

  tipos = TIPOS_DOCUMENTOS;

  epss: Eps[] = [];
  eps: Eps = null;

  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  onChangeProveedor(proveedor: Eps) {
    this.eps = proveedor;
  }

  ngOnInit() {
    this.token.validate();
    this.titleService.setTitle(`${NAME_APP} - Reporte Dinamico`);

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

  cambiarFiltro(f: string) {
    this.filtrado = f;
    this.documento = "";
    this.tipoDocumento = this.tipos[0].value;
    this.prescripcion = "";
  }

  busqueda() {
    if (this.eps != null && this.regimen != null) {
        Swal.fire({
          title: "Generando archivo ... ",
          allowOutsideClick: false,
          onBeforeOpen: () => Swal.showLoading(),
        });
        let nombreReporte = "general-dinamico";

        switch (this.filtrado) {
          case "f":
            nombreReporte += "-" + this.fechaInicio + "_" + this.fechaFin;
            break;
          case "a":
            nombreReporte += "-" + this.tipoDocumento + "_" + this.documento;
            break;
          case "p":
            nombreReporte += "-" + this.prescripcion;
            break;
        }
        if (this.documento.trim().length == 0) {
          this.documento = "null";
        }
        if (this.prescripcion.trim().length == 0) {
          this.prescripcion = "null";
        }

        if (this.tipoBusqueda == "p") {
          this.apiService.prescripcionesService
            .planInformeRecobros(
              this.tipo,
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaInicio,
              this.fechaFin,
              this.tipoDocumento,
              this.documento,
              this.prescripcion
            )
            .subscribe(
              (response) => {
                this.apiService.utilService.downloadFile(
                  response,
                  nombreReporte,
                  "xlsx"
                );
              },
              (error) => {
                console.log(error);
                Swal.close();
                this.apiService.notifService.error("Error", error);
              }
            );
        } else {
          this.apiService.tutelasService
            .planInformeRecobros(
              this.tipo,
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaInicio,
              this.fechaFin,
              this.tipoDocumento,
              this.documento,
              this.prescripcion
            )
            .subscribe(
              (response) => {
                this.apiService.utilService.downloadFile(
                  response,
                  nombreReporte,
                  "xlsx"
                );
              },
              (error) => {
                console.log(error);
                Swal.close();
                this.apiService.notifService.error("Error", error);
              }
            );
        }
    } else {
      Swal.fire("Error", "Debe se seleccionar el regimen de la eps", "info");
    }
  }

}
