import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class InformeAutorizacionesService {

  baseUrl = API_REST;

  constructor(private http: HttpClient) {}

  xlsxInformeTutelas(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}tutela/xlsx-informe/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxInformePrescripciones(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string,
    estados: string
  ) {
    return this.http.get(
      `${this.baseUrl}prescripcion/xlsx-informe/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}/${estados}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  xlsxInformeFacturacionPrescripciones(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string
  ) {
    return this.http.get(
      `${this.baseUrl}prescripcion/xlsx-informe-facturacion/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}?format=xlsx`,
      { responseType: "blob" }
    );
  }
  
  xlsxInformeFacturacionTutelas(
    tipo: number,
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string
  ) {
    return this.http.get(
      `${this.baseUrl}tutela/xlsx-informe-facturacion/${tipo}/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
