import { PrescripcionesApi } from "./../models/containers/prescripciones-api.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class PrescripcionesService {
  baseUrl = API_REST + "prescripcion/";

  constructor(private http: HttpClient) {}

  getPorFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<PrescripcionesApi>(
      `${this.baseUrl}byFecha/${nit}/${fecha}/${regimen}`
    );
  }

  getPorPaciente(tipoDoc: string, numDoc: string, nit: string, regimen: string, fecha: string) {
    return this.http.get<PrescripcionesApi>(
      `${this.baseUrl}byPaciente/${nit}/${fecha}/${regimen}/${tipoDoc}/${numDoc}`
    );
  }

  getPorNumero(nit: string, regimen: string, numero: string) {
    return this.http.get<PrescripcionesApi>(
      `${this.baseUrl}byNumero/${nit}/${regimen}/${numero}`
    );
  }

  getPorNumeroPdf(nit: string, regimen: string, numero: string) {
    return this.http.get(
      `${this.baseUrl}pdf/${nit}/${regimen}/${numero}?format=pdf`,
      { responseType: "blob" }
    );
  }

  getPorIdentificadorPdf(nit: string, regimen: string, numero: string, idDireccionamiento: number) {
    return this.http.get(
      `${this.baseUrl}pdf/${nit}/${regimen}/${numero}/${idDireccionamiento}?format=pdf`,
      { responseType: "blob" }
    );
  }

  planReporteGeneralDinamico(
    tipo: string,
    nit: string,
    regimen: string,
    fechaInicio: string,
    fechaFin: string,
    tipoId: string,
    numeroId: string,
    numero: string,
    campos: any
  ) {
    let url = `${this.baseUrl}plan-general/${tipo}/${nit}/${regimen}/${fechaInicio}/${fechaFin}/${tipoId}/${numeroId}/${numero}?format=xlsx`;
    return this.http.post(url, campos, { responseType: "blob" });
  }

  planInformeRecobros(
    tipo: string,
    nit: string,
    regimen: string,
    fechaInicio: string,
    fechaFin: string,
    tipoId: string,
    numeroId: string,
    numero: string
  ) {
    let url = `${this.baseUrl}recobros/${tipo}/${nit}/${regimen}/${fechaInicio}/${fechaFin}/${tipoId}/${numeroId}/${numero}?format=xlsx`;
    return this.http.get(url, { responseType: "blob" });
  }

}
