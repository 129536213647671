import { Injectable } from '@angular/core';
import { API_REST } from '../url.constants';
import { HttpClient } from '@angular/common/http';
import { TarifasMensualApi } from '../models/containers/tarifas-mensual-api.model';

@Injectable({
  providedIn: 'root'
})
export class TarifaMensualService {
  baseUrl = API_REST + "tarifaMensual/";

  constructor(private http: HttpClient) {}

  getTarifas(regimen: string) {
    return this.http.get<TarifasMensualApi>(`${this.baseUrl}${regimen}`);
  }

  getTarifasExcel(regimen: string, anio: number, mes: number) {
    return this.http.get(
      `${this.baseUrl}xlsx/${regimen}/${anio}/${mes}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
