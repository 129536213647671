import { NAME_APP } from './../../url.constants';
import { Router } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { ROLES } from './../../data/datos.constants';
import { Rol } from './../../models/rol.model';
import { Usuario } from 'src/app/models/usuario.model';
import { UsuarioSalida } from './../../models/salida/usuario-salida.model';
import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { JwtResponse } from 'src/app/auth/jwt-response';
import { Eps } from 'src/app/models/eps.model';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main-usuario-edit',
  templateUrl: './main-usuario-edit.component.html',
  styleUrls: ['./main-usuario-edit.component.scss']
})
export class MainUsuarioEditComponent implements OnInit {

  usuario: Usuario;
  usuarioEdit: Usuario;
  usuarioForm: FormGroup;

  info: JwtResponse;

  epss: Eps[] = [];
  eps: Eps = null;

  roles : Rol[] = ROLES;
  rol: Rol = null;

  constructor(private token: TokenStorageService, private apiService: ApiService,
    private fb: FormBuilder, private router: Router,private titleService: Title
    ) {}

    ngOnInit() {
      this.titleService.setTitle(`${NAME_APP} - Editar usuario`);
    this.token.validate();
    this.info = this.token.getInfo();
    this.usuario = new Usuario();

    let usuarioId = window.localStorage.getItem("editUsuarioId");
    if (!usuarioId) {
      this.router.navigate(['/main/usuarios']);
      return;
    }

    this.usuarioForm = this.fb.group({
      nombres: ['', Validators.required],
      apellidos: ['', Validators.required],
      email: ['', Validators.required],
      identificacion: ['', Validators.required],
      nombreTipoId: ['', Validators.required],
      enable: [''],
      auditor:['']
    });

    if (this.token.isRol('ROLE_ADMIN')) {
      this.apiService.epsService.getListaEps()
        .subscribe(
          data => {
            if (data.epss) {
              this.epss = data.epss;
              if (this.epss.length > 0) {
                this.eps = this.epss[0];
              }
            }
          }
        );

      this.apiService.usuarioService.getUsuarioPorIdentificacion(usuarioId)
      .subscribe(data => {
        this.usuario = data.usuario;
        this.usuarioForm.patchValue(data.usuario);
        this.usuarioForm.patchValue({
          nombreTipoId: this.usuario.tipoId.descripcion
        });
        if (this.usuario.eps) {
          this.epss.forEach(element => {
            if (element.identificacion == this.usuario.eps.identificacion) {
              this.eps = element
            }
          });
        }
        this.roles.forEach(element => {
          if (element.rol == this.usuario.rol.rol) {
            this.rol = element;
          }
        });
      })
    }
  }

  onSubmit() {

    this.usuarioEdit = Object.assign({}, this.usuarioForm.value);

    let usuarioSave = new UsuarioSalida();

    usuarioSave.email = this.usuarioEdit.email;
    usuarioSave.enable = this.usuarioEdit.enable;
    usuarioSave.identificacion = this.usuarioEdit.identificacion;
    usuarioSave.auditor = this.usuarioEdit.auditor;

    usuarioSave.nit = this.eps ? this.eps.identificacion : '';
    usuarioSave.rol = this.rol ? this.rol.rol: '';

    this.apiService.usuarioService.updateUsuario(usuarioSave).subscribe(data => {
      this.router.navigate(['/main/usuarios']);
    }, error => {
      console.log(error)
      this.apiService.notifService.error('Error', error);
    });

  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
