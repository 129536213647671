import { SuministroSalida } from "./../../../models/salida/suministro-salida.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "./../../../services/api.service";
import { CausaNoEntrega } from "./../../../models/causa-no-entrega.model";
import { ViewChild, ElementRef, Output, EventEmitter } from "@angular/core";
import { ReporteEntrega } from "./../../../models/msumin/reporte-entrega.model";
import { Component, OnInit, Input } from "@angular/core";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import Swal from "sweetalert2";

@Component({
  selector: "app-modal-reporte-entrega-detalle",
  templateUrl: "./modal-reporte-entrega-detalle.component.html",
  styleUrls: ["./modal-reporte-entrega-detalle.component.scss"]
})
export class ModalReporteEntregaDetalleComponent implements OnInit {
  @Input() public reporte: ReporteEntrega;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Output() public showModalReportesEntregas = new EventEmitter();

  causasNoEntrega: CausaNoEntrega[] = [];
  causaNoEntrega: CausaNoEntrega = null;

  enviandoForm: boolean = false;

  suministroSalida: SuministroSalida = null;
  suministroSalidaEdit: SuministroSalida = null;

  suministroForm: FormGroup;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.reporte) {
      this.suministroSalida = new SuministroSalida();
      this.suministroSalida.ID = this.reporte.ID;
      this.suministroSalida.NoPrescripcionAsociada = this.reporte.NoPrescripcion;
      this.suministroSalida.ConTecAsociada = this.reporte.ConTec;
      this.suministroSalida.CantTotEntregada = this.reporte.CantTotEntregada;
      this.suministroSalida.NoLote = this.reporte.NoLote;
      this.suministroSalida.ValorEntregado = this.reporte.ValorEntregado;

      this.apiService.codigosMipresService
        .getCausasNoEntregaPorTipo(this.reporte.TipoTec)
        .subscribe(data => {
          this.causasNoEntrega = data;
        });
      this.suministroForm = this.fb.group({
        EntregaCompleta: [false],
        UltEntrega: [false]
      });
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    if (this.prescripcion) {
      this.showModalReportesEntregas.emit(this.prescripcion);
    }
  }

  onSubmit() {
    this.enviandoForm = true;
    let { EntregaCompleta, UltEntrega } = this.suministroForm.value;

    this.suministroSalidaEdit = this.suministroSalida;

    this.suministroSalidaEdit.EntregaCompleta = EntregaCompleta ? 1 : 0;
    this.suministroSalidaEdit.UltEntrega = UltEntrega ? 1 : 0;

    this.suministroSalidaEdit.CausaNoEntrega = this.causaNoEntrega
      ? this.causaNoEntrega.codigo
      : 0;

    this.suministroSalidaEdit.ValorEntregado = this.suministroSalidaEdit.ValorEntregado.replace('.', ',');

    if (this.suministroSalidaEdit.CausaNoEntrega != 0) {
      this.suministroSalidaEdit.EntregaCompleta = null;
      this.suministroSalidaEdit.UltEntrega = null;
    }

    console.log(this.suministroSalidaEdit);
    this.apiService.suministrosService
      .enviar(
        this.eps.identificacion,
        this.regimen.codigo,
        this.suministroSalidaEdit
      )
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El suministro se ha registro con exito"
          });
          this.enviandoForm = false;
          this.hideModal();
        },
        error => {
          console.log(error);
          this.enviandoForm = false;
          this.apiService.notifService.error("Error", error);
        }
      );
  }
}
