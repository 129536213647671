import { CausaNoEntrega } from "./../../../models/causa-no-entrega.model";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "./../../../services/api.service";
import { TutelaApi } from "src/app/models/mpresc/tutela-api.model";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { NoDireccionamientoSalida } from "./../../../models/salida/no-direccionamiento-salida.model";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";
import { FormGroup } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-modal-no-direccionamiento-add",
  templateUrl: "./modal-no-direccionamiento-add.component.html",
  styleUrls: ["./modal-no-direccionamiento-add.component.scss"]
})
export class ModalNoDireccionamientoAddComponent implements OnInit {
  @Input() public salida: NoDireccionamientoSalida;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Output() public showModalPrescripcion = new EventEmitter();
  @Output() public showModalTutela = new EventEmitter();

  direccionamientoForm: FormGroup;

  salidaEdit: NoDireccionamientoSalida = null;
  causasNoEntrega: CausaNoEntrega[] = [];
  causaNoEntrega: CausaNoEntrega = null;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.apiService.codigosMipresService
        .getCausasNoEntregaPorTipo(this.salida.TipoTec)
        .subscribe(data => {
          this.causasNoEntrega = data;
          this.causaNoEntrega = this.causasNoEntrega
            ? this.causasNoEntrega[0]
            : null;
        });
      this.direccionamientoForm = this.fb.group({
        NoPrescripcionAsociada: [],
        ConTecAsociada: []
      });
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    if (this.prescripcion) {
      this.showModalPrescripcion.emit(this.prescripcion);
    }
    if (this.tutela) {
      this.showModalTutela.emit(this.tutela);
    }
  }

  onSubmit() {
    this.salidaEdit = Object.assign({}, this.direccionamientoForm.value);

    this.salidaEdit.ConTec = this.salida.ConTec;
    this.salidaEdit.TipoTec = this.salida.TipoTec;
    this.salidaEdit.NoPrescripcion = this.salida.NoPrescripcion;

    this.salidaEdit.TipoIDPaciente = this.salida.TipoIDPaciente;
    this.salidaEdit.NoIDPaciente = this.salida.NoIDPaciente;

    this.salidaEdit.CausaNoEntrega = this.causaNoEntrega.codigo;

    this.apiService.noDireccionamientosService
      .enviar(this.eps.identificacion, this.regimen.codigo, this.salidaEdit)
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El no direccionamiento se ha realizado con exito"
          });
          this.hideModal();
        },
        error => {
          console.log(error);
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  getDescTipo(tipo: string) {
    switch (tipo) {
      case "M":
        return "Medicamento";
      case "D":
        return "Dispositivo";
      case "P":
        return "Procedimiento";
      case "N":
        return "Producto nutricional";
      case "S":
        return "Servicio complementario";
      default:
        return "";
    }
  }
}
