export class SuministroSalida {
  ID: number;
	UltEntrega: number;
	EntregaCompleta: number;
	CausaNoEntrega: number;
	NoPrescripcionAsociada: string;
	ConTecAsociada: number;
	CantTotEntregada: string;
	NoLote: string;
	ValorEntregado: string;
}
