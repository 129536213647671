import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Eps } from "src/app/models/eps.model";
import { Direccionamiento } from "src/app/models/msumin/direccionamiento.model";
import { Regimen } from "src/app/models/regimen.model";
import { DireccionamientoAnularSalida } from "src/app/models/salida/direccionamiento-anular-salida.model";
import Swal from 'sweetalert2';
import { TokenStorageService } from "../../../services/auth/token-storage.service";
import { ApiService } from "./../../../services/api.service";

@Component({
  selector: "app-modal-direccionamientos-detalle",
  templateUrl: "./modal-direccionamientos-detalle.component.html",
  styleUrls: ["./modal-direccionamientos-detalle.component.scss"]
})
export class ModalDireccionamientosDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  direccionamientos: Direccionamiento[];
  cargando: boolean = false;

  constructor(private apiService: ApiService, private token: TokenStorageService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.direccionamientos = [];
      this.cargando = true;
      if (this.tutela) {
        this.apiService.direccionamientosService
          .getPrescripcionTutel(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.direccionamientos) {
                this.direccionamientos = data.direccionamientos;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );
      } else {
        this.apiService.direccionamientosService
          .getPrescripcionPresc(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.direccionamientos) {
                this.direccionamientos = data.direccionamientos;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );

      }
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

  saveAuditoria(dir: Direccionamiento) {
    this.apiService.direccionamientosService
      .auditarFaltante(this.tutela, this.eps.identificacion, this.regimen.codigo, dir)
      .subscribe(data => {
        this.apiService.notifService.success('Hecho', data['msj']);
      }, error => {
        this.apiService.notifService.error("Error", error);
      });
  }

  anular(dir: Direccionamiento) {

    let entrada = new DireccionamientoAnularSalida();

    entrada.conTec = dir.ConTec;
    entrada.idDireccionamientoRef = dir.IDDireccionamiento;
    entrada.idRef = dir.ID;
    entrada.numPrescripcion = dir.NoPrescripcion;
    entrada.tipoTec = dir.TipoTec;

    this.apiService.direccionamientosService
      .anular(this.eps.identificacion, this.regimen.codigo, entrada)
      .subscribe(data => {
        this.cargando=true;
        setTimeout(() => this.cargarInformacion(), 1500);
      }, error => {
        this.apiService.notifService.error("Error", error);
      });
  }

  reenviarDireccionamiento2(dir: Direccionamiento, archivo: any = null) {
    Swal.fire({
      title: 'Enviando email ... ',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    let reenviarEmail = {};
    reenviarEmail['id'] = dir.ID;
    reenviarEmail['idDir'] = dir.IDDireccionamiento;
    reenviarEmail['municipio'] = dir.CodMunEnt;
    reenviarEmail['numEntrega'] = dir.NoEntrega;
    reenviarEmail['numIdPac'] = dir.NoIDPaciente;
    reenviarEmail['tipoIdPac'] = dir.TipoIDPaciente
    reenviarEmail['numIdProv'] = dir.NoIDProv;
    reenviarEmail['numeroPrescripcion'] = dir.NoPrescripcion;

    let formulario: any = reenviarEmail;
    let form_data = new FormData();
    for (let key in reenviarEmail) {
      form_data.append(key, reenviarEmail[key]);
    }
    if (archivo) {
      form_data.append('file', archivo);
    }
    formulario = form_data;
    
    this.apiService.direccionamientosService.
    reenviarEmailProv(
      this.tutela, this.eps.identificacion, this.regimen.codigo, formulario
    ).subscribe(data => {
      Swal.close();
      if (data) {
        this.apiService.notifService.success('Hecho', 'Email reenviado al proveedor');
      } else {
        this.apiService.notifService.error('Error', 'Se produjo un error al tratar de reenviar el email al proveedor.');
      }
    }, error => {
      Swal.close();
      console.log(error);
      this.apiService.notifService.error('Error', error);
    })
  }

  reenviarDireccionamiento(dir: Direccionamiento) {
    Swal.fire({
      title: "Reenviar Notificación a prestador",
      input: "file",
      inputAttributes: {
        "accept": "application/pdf",
        "aria-label": "Soporte adicional"
      },
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
              this.reenviarDireccionamiento2(dir, value)
              resolve(null);
          } else {
              this.reenviarDireccionamiento2(dir)
              resolve(null)
          }
        });
      }
    });    
  }

  pdfDireccionamiento(dir: Direccionamiento) {
    Swal.fire({
      title: 'Generando archivo ... ',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    if (this.tutela) {
      this.apiService.tutelasService
        .getPorIdentificadorPdf(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion,
          dir.IDDireccionamiento
        )
        .subscribe(response => {
          Swal.close();
          this.apiService.utilService.downloadFile(
            response,
            "Direccionamiento - " + dir.IDDireccionamiento,
            "pdf"
          );
        }, error => {
          Swal.close();
          console.log(error);
          this.apiService.notifService.error('Error', error);
        });
    } else {
      this.apiService.prescripcionesService
        .getPorIdentificadorPdf(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion,
          dir.IDDireccionamiento
        )
        .subscribe(response => {
          Swal.close();
          this.apiService.utilService.downloadFile(
            response,
            "Direccionamiento - " + dir.IDDireccionamiento,
            "pdf"
          );
        }, error => {
          Swal.close();
          console.log(error);
          this.apiService.notifService.error('Error', error);
        });
      }
  }
}
