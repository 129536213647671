import { ApiService } from "./../../../services/api.service";
import { Eps } from "./../../../models/eps.model";
import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, ElementRef } from "@angular/core";
import { Regimen } from "src/app/models/regimen.model";
import { RegimenEpsSalida } from "src/app/models/salida/regimen-eps-salida.model";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import Swal from "sweetalert2";

@Component({
  selector: "app-modal-eps-regimenes",
  templateUrl: "./modal-eps-regimenes.component.html",
  styleUrls: ["./modal-eps-regimenes.component.scss"]
})
export class ModalEpsRegimenesComponent implements OnInit {

  @Input() public eps: Eps;
  @Output() public UpdateEps = new EventEmitter();

  show: boolean = false;
  regimenes: Regimen[] = [];

  // Administrar regimen de la eps
  regimenSalida: RegimenEpsSalida;
  regimenAddForm: FormGroup;
  showRegimenAddForm: boolean;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.regimenes = this.eps.regimenes;
      this.UpdateEps.emit(this.eps);
    }
    this.regimenAddForm = this.fb.group({
      nombre: ["", Validators.required],
      codigo: ["", Validators.required]
    });
    this.regimenSalida = null;
    this.showRegimenAddForm = false;
  }

  showModal() {
    this.show = true;
    this.ngOnInit();
  }

  hideModal() {
    this.show = false;
    this.ngOnInit();
  }

  formularioAgregarRegimen() {
    this.regimenSalida = new RegimenEpsSalida();
    this.regimenAddForm.reset();
    this.showRegimenAddForm = true;
  }

  onSubmitRegimenAdd() {
    this.regimenSalida = Object.assign({}, this.regimenAddForm.value);

    this.apiService.epsService
      .saveRegimen(this.regimenSalida)
      .subscribe(data => {
        if (data && data.eps) {
          this.eps = data.eps;
          this.ngOnInit();
        }
      });
  }

  deleteRegimen(regimen: Regimen) {
    Swal.fire({
      title: "Procesando datos",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    this.apiService.epsService
      .deleteRegimen(regimen.codigo)
      .subscribe(
        data => {
          Swal.close();
          if (data && data.eps) {
            this.eps = data.eps;
            this.ngOnInit();
          } else {
            Swal.fire({
              title: "Error al eliminar",
              text: `No se logró eliminar el regimen, intenta de nuevo!`,
              type: "error"
            });
          }
        }
      );
  }
}
