import { ApiService } from 'src/app/services/api.service';
import { Eps } from 'src/app/models/eps.model';
import Swal from 'sweetalert2';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { TipoTarea } from 'src/app/data/datos.constants';

@Component({
  selector: 'app-modal-registrar-tarea',
  templateUrl: './modal-registrar-tarea.component.html',
  styles: []
})
export class ModalRegistrarTareaComponent implements OnInit {

  @Input() eps: Eps;
  @Output() UpdateList = new EventEmitter();

  selectedFiles: FileList;
  currentFileUpload: File;
  progress: { percentage: number } = { percentage: 0 };

  tipoPlan = TipoTarea;
  tipoPlanSelect = this.tipoPlan[TipoTarea.CARGUE_SUMINISTROS];
  tiposPlanes = Object.values(TipoTarea).filter(key => !isNaN(Number(TipoTarea[key])));

  nameFile: string = null;
  observacion = '';
  regimen = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.regimen = this.eps.regimenes[0].codigo;
    }
  }

  handleFileInput(event: any) {
    this.selectedFiles = event.target.files;
    this.nameFile = this.selectedFiles.item(0).name;
    this.currentFileUpload = null;
    this.progress.percentage = 0;
  }

  onChangeTipo(tipo: any) {
    this.tipoPlanSelect = tipo;
  }

  onSubmit() {
    this.progress.percentage = 0;
    const formdata: FormData = new FormData();
    if (!this.nameFile) {
      Swal.fire('Error', 'Debe cargar el archivo solicitado', 'error');
      return;
    }

    Swal.fire({
      title: 'Procesando datos',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    formdata.append('file', this.selectedFiles.item(0));
    formdata.append('nit', this.eps.identificacion);
    formdata.append('tipo', this.tipoPlanSelect);
    formdata.append('observacion', this.observacion);
    formdata.append('regimen', this.regimen);

    this.apiService.tareasService
      .registrarCargue(formdata)
      .subscribe(
        event => {
          if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(
              (100 * event.loaded) / event.total
            );
          } else if (event instanceof HttpResponse) {
            Swal.close();
            if (event.ok) {
              this.currentFileUpload = null;
              this.progress.percentage = 0;
              this.apiService.notifService.success(
                'Hecho', 'Se ha realizado el cargue de la tarea con exito'
              );
              this.UpdateList.emit(undefined);
            } else {
              this.apiService.notifService.error('Error', event);
            }
          }
        },
        error => {
          Swal.close();
          this.apiService.notifService.error('Error', error);
          console.error(error);
        }
      );
  }

}
