import { UsuarioApi } from "./../models/containers/usuario-api.model";
import { UsuariosApi } from "./../models/containers/usuarios-api.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { UsuarioSalida } from "../models/salida/usuario-salida.model";

@Injectable({
  providedIn: "root"
})
export class UsuarioService {
  baseUrl = API_REST + "usuario/";

  constructor(private http: HttpClient) {}

  getListaUsuarios() {
    return this.http.get<UsuariosApi>(`${this.baseUrl}all`);
  }

  getListaUsuariosPorEps(nit: string) {
    return this.http.get<UsuariosApi>(`${this.baseUrl}byEps/${nit}`);
  }

  getUsuarioPorIdentificacion(identificacion: string) {
    return this.http.get<UsuarioApi>(
      `${this.baseUrl}byUsuario/${identificacion}`
    );
  }

  setPasswordUsuario(password: string) {
    return this.http.post<boolean>(`${this.baseUrl}passwordEdit`, password);
  }

  setEstadoUsuario(identificacion: string, estado: boolean) {
    return this.http.post<boolean>(
      `${this.baseUrl}enableEdit/${identificacion}/${estado}`,
      {}
    );
  }

  setAuditorUsuario(identificacion: string, auditor: boolean) {
    return this.http.post<boolean>(
      `${this.baseUrl}auditorEdit/${identificacion}/${auditor}`,
      {}
    );
  }

  saveUsuario(usuario: UsuarioSalida) {
    return this.http.post<UsuarioApi>(`${this.baseUrl}`, usuario);
  }

  updateUsuario(usuario: UsuarioSalida) {
    return this.http.post<UsuarioApi>(`${this.baseUrl}update`, usuario);
  }
}
