import { ModalBuscarTutelasComponent } from './../modal-buscar-tutelas/modal-buscar-tutelas.component';
import { ModalAuditoriaDetalleComponent } from './../modals/modal-auditoria-detalle/modal-auditoria-detalle.component';
import { ModalFacturacionDetalleComponent } from './../modals/modal-facturacion-detalle/modal-facturacion-detalle.component';
import { ModalFacturacionesDetalleComponent } from './../modals/modal-facturaciones-detalle/modal-facturaciones-detalle.component';
import { ModalDatosFacturadosDetalleComponent } from './../modals/modal-datos-facturados-detalle/modal-datos-facturados-detalle.component';
import { ModalSuministroDetalleComponent } from './../modals/modal-suministro-detalle/modal-suministro-detalle.component';
import { ModalSuministrosDetalleComponent } from './../modals/modal-suministros-detalle/modal-suministros-detalle.component';
import { ModalReporteEntregaDetalleComponent } from './../modals/modal-reporte-entrega-detalle/modal-reporte-entrega-detalle.component';
import { ModalReportesEntregasDetalleComponent } from './../modals/modal-reportes-entregas-detalle/modal-reportes-entregas-detalle.component';
import { ModalNoDireccionamientoAddComponent } from "./../modals/modal-no-direccionamiento-add/modal-no-direccionamiento-add.component";
import { ModalNoDireccionamientosDetalleComponent } from "./../modals/modal-no-direccionamientos-detalle/modal-no-direccionamientos-detalle.component";
import { NAME_APP } from "./../../url.constants";
import { ModalAuditarPrescripcionComponent } from "./../modals/modal-auditar-prescripcion/modal-auditar-prescripcion.component";
import { ModalDireccionamientoAddComponent } from "./../modals/modal-direccionamiento-add/modal-direccionamiento-add.component";
import { ModalDireccionamientosDetalleComponent } from "./../modals/modal-direccionamientos-detalle/modal-direccionamientos-detalle.component";
import { ModalProductoNutricionalDetalleComponent } from "./../modals/modal-producto-nutricional-detalle/modal-producto-nutricional-detalle.component";
import { ModalProcedimientoDetalleComponent } from "./../modals/modal-procedimiento-detalle/modal-procedimiento-detalle.component";
import { ModalServicioComplementarioDetalleComponent } from "./../modals/modal-servicio-complementario-detalle/modal-servicio-complementario-detalle.component";
import { ModalMedicamentoDetalleComponent } from "./../modals/modal-medicamento-detalle/modal-medicamento-detalle.component";
import { ModalPrescripcionDetalleComponent } from "./../modals/modal-prescripcion-detalle/modal-prescripcion-detalle.component";
import { ApiService } from "./../../services/api.service";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

import * as moment from "moment";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { ModalDispositivoMedicoDetalleComponent } from "../modals/modal-dispositivo-medico-detalle/modal-dispositivo-medico-detalle.component";
import { Title } from "@angular/platform-browser";
import { ModalBuscarTarifasComponent } from '../modals/modal-buscar-tarifas/modal-buscar-tarifas.component';
import { ModalBuscarHomologosComponent } from '../modals/modal-buscar-homologos/modal-buscar-homologos.component';
import {ModalFacturaRipsComponent} from '../modals/modal-factura-rips/modal-factura-rips.component';

@Component({
  selector: "app-main-prescripciones",
  templateUrl: "./main-prescripciones.component.html",
  styleUrls: ["./main-prescripciones.component.scss"]
})
export class MainPrescripcionesComponent implements OnInit {
  now = moment();
  filtrado: string = "f";
  fecha: string = this.now.format("YYYY-MM-DD");
  fechaAfiliado: string = this.now.format("YYYY-MM-DD");
  tipoDocumento: string = "CC";
  documento: string = "";
  prescripcion: string = "";
  primera: boolean = false;
  cargando: boolean = false;

  prescripciones: PrescripcionApi[] = [];

  tipos = TIPOS_DOCUMENTOS;

  eps: Eps = null;

  regimen: Regimen = null;

  @ViewChild("modalPrescripcion", { static: false })
  modalPrescripcion: ModalPrescripcionDetalleComponent;

  @ViewChild("modalAuditoria", { static: false })
  modalAuditoria: ModalAuditoriaDetalleComponent;

  @ViewChild("modalDatosFacturacion", { static: false })
  modalDatosFacturacion: ModalDatosFacturadosDetalleComponent;

  @ViewChild("modalFacturaciones", { static: false })
  modalFacturaciones: ModalFacturacionesDetalleComponent;

  @ViewChild("modalFacturacion", { static: false })
  modalFacturacion: ModalFacturacionDetalleComponent;

  @ViewChild("modalDireccionamientos", { static: false })
  modalDireccionamientos: ModalDireccionamientosDetalleComponent;

  @ViewChild("modalNoDireccionamientos", { static: false })
  modalNoDireccionamientos: ModalNoDireccionamientosDetalleComponent;

  @ViewChild("modalReportesEntregas", { static: false })
  modalReportesEntregas: ModalReportesEntregasDetalleComponent;

  @ViewChild("modalReporteEntrega", { static: false })
  modalReporteEntrega: ModalReporteEntregaDetalleComponent;

  @ViewChild("modalSuministros", { static: false })
  modalSuministros: ModalSuministrosDetalleComponent;

  @ViewChild("modalSuministro", { static: false })
  modalSuministro: ModalSuministroDetalleComponent;

  @ViewChild("modalMedicamento", { static: false })
  modalMedicamento: ModalMedicamentoDetalleComponent;

  @ViewChild("modalServicioCompl", { static: false })
  modalServicioCompl: ModalServicioComplementarioDetalleComponent;

  @ViewChild("modalProcedimiento", { static: false })
  modalProcedimiento: ModalProcedimientoDetalleComponent;

  @ViewChild("modalProductoNutric", { static: false })
  modalProductoNutric: ModalProductoNutricionalDetalleComponent;

  @ViewChild("modalDispositivoMedic", { static: false })
  modalDispositivoMedic: ModalDispositivoMedicoDetalleComponent;

  @ViewChild("modalDireccionamientoAdd", { static: false })
  modalDireccionamientoAdd: ModalDireccionamientoAddComponent;

  @ViewChild("modalBuscarTarifas", { static: false })
  modalBuscarTarifas: ModalBuscarTarifasComponent;

  @ViewChild("modalBuscarTutelas", { static: false })
  modalBuscarTutelas: ModalBuscarTutelasComponent;

  @ViewChild("modalBuscarHomologos", { static: false })
  modalBuscarHomologos: ModalBuscarHomologosComponent;

  @ViewChild("modalNoDireccionamientoAdd", { static: false })
  modalNoDireccionamientoAdd: ModalNoDireccionamientoAddComponent;

  @ViewChild("modalAuditarPrescripcion", { static: false })
  modalAuditarPrescripcion: ModalAuditarPrescripcionComponent;

  @ViewChild("showModalPrescripcion", { static: false })
  showModalPrescripcion: ElementRef;

  @ViewChild("showModalDireccionamiento", { static: false })
  showModalDireccionamiento: ElementRef;

  @ViewChild("showModalNoDireccionamiento", { static: false })
  showModalNoDireccionamiento: ElementRef;

  @ViewChild("showModalReportesEntregas", { static: false })
  showModalReportesEntregas: ElementRef;

  @ViewChild("showModalSuministros", { static: false })
  showModalSuministros: ElementRef;

  @ViewChild("showModalSuministro", { static: false })
  showModalSuministro: ElementRef;

  @ViewChild("showModalFacturaciones", { static: false })
  showModalFacturaciones: ElementRef;

  @ViewChild("showModalFacturacion", { static: false })
  showModalFacturacion: ElementRef;

  @ViewChild("showModalDatosFacturacion", { static: false })
  showModalDatosFacturacion: ElementRef;

  @ViewChild("showModalAuditoriaDetalle", { static: false })
  showModalAuditoriaDetalle: ElementRef;

  @ViewChild("showModalAuditar", { static: false })
  showModalAuditar: ElementRef;

  @ViewChild("modalFacturaRips", { static: false })
  modalFacturaRips: ModalFacturaRipsComponent;

  @ViewChild("showModalFacturaRips", { static: false })
  showModalFacturaRips: ElementRef;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Prescripciones`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  cambiarFiltro(f: string) {
    this.prescripciones = [];
    this.filtrado = f;
  }

  validarPermisoPorAmbito(presc: PrescripcionApi) {
    if ([22, 30].includes(presc.prescripcion.CodAmbAte)) {
      return true;
    }
    return presc.auditoria.procesada;
  }

  busqueda() {
    this.prescripciones = [];
    this.cargando = true;

    switch (this.filtrado) {
      case "f":
        this.apiService.prescripcionesService
          .getPorFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.prescripciones = [];
              if (data.prescripciones) {
                this.prescripciones = data.prescripciones;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "a":
        this.apiService.prescripcionesService
          .getPorPaciente(
            this.tipoDocumento,
            this.documento,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaAfiliado
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.prescripciones = [];
              if (data.prescripciones) {
                this.prescripciones = data.prescripciones;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "p":
        this.apiService.prescripcionesService
          .getPorNumero(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.prescripciones = [];
              if (data.prescripciones) {
                this.prescripciones = data.prescripciones;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );
        break;
    }
  }

  detalle(presc: PrescripcionApi) {
    this.modalPrescripcion.prescripcion = presc;
    this.modalPrescripcion.eps = this.eps;
    this.modalPrescripcion.regimen = this.regimen;
    this.showModalPrescripcion.nativeElement.click();
    this.modalPrescripcion.showModal();
  }

  auditoria(presc: PrescripcionApi) {
    this.modalAuditoria.prescripcion = presc;
    this.modalAuditoria.eps = this.eps;
    this.modalAuditoria.regimen = this.regimen;
    this.showModalAuditoriaDetalle.nativeElement.click();
    this.modalAuditoria.showModal();
  }

  direccionamientos(presc: PrescripcionApi) {
    this.modalDireccionamientos.prescripcion =
      presc.prescripcion.NoPrescripcion;
    this.modalDireccionamientos.eps = this.eps;
    this.modalDireccionamientos.regimen = this.regimen;
    this.modalDireccionamientos.tutela=false;
    this.showModalDireccionamiento.nativeElement.click();
    this.modalDireccionamientos.showModal();
  }

  noDireccionamientos(presc: PrescripcionApi) {
    this.modalNoDireccionamientos.prescripcion =
      presc.prescripcion.NoPrescripcion;
    this.modalNoDireccionamientos.eps = this.eps;
    this.modalNoDireccionamientos.regimen = this.regimen;
    this.showModalNoDireccionamiento.nativeElement.click();
    this.modalNoDireccionamientos.showModal();
  }

  reportesEntregas(presc: PrescripcionApi) {
    this.reportesEntregasGenerico(presc.prescripcion.NoPrescripcion);
  }

  reportesEntregasGenerico(pres: string) {
    this.modalReportesEntregas.prescripcion = pres;
    this.modalReportesEntregas.eps = this.eps;
    this.modalReportesEntregas.regimen = this.regimen;
    this.modalReportesEntregas.tutela = false;
    this.showModalReportesEntregas.nativeElement.click();
    this.modalReportesEntregas.showModal();
  }

  detalleReporteEntrega(data: any) {
    this.modalReportesEntregas.hideModal();
    this.modalReporteEntrega.reporte = data.rep;
    this.modalReporteEntrega.prescripcion = data.presc;
    this.modalReporteEntrega.eps = this.eps;
    this.modalReporteEntrega.regimen = this.regimen;
    this.modalReporteEntrega.showModal();
  }

  suministros(presc: PrescripcionApi) {
    this.suministrosGenerico(presc.prescripcion.NoPrescripcion);
  }

  suministrosGenerico(pres: string) {
    this.modalSuministros.prescripcion = pres;
    this.modalSuministros.eps = this.eps;
    this.modalSuministros.regimen = this.regimen;
    this.modalSuministros.tutela = false;
    this.showModalSuministros.nativeElement.click();
    this.modalSuministros.showModal();
  }

  detalleSuministro(data: any) {
    this.modalSuministros.hideModal();
    this.modalSuministro.suministro = data.sum;
    this.modalSuministro.prescripcion = data.presc;
    this.modalSuministro.showModal();
  }

  facturaciones(presc: PrescripcionApi) {
    this.facturacionesGenerico(presc.prescripcion.NoPrescripcion);
  }

  facturacionesGenerico(pres: string) {
    this.modalFacturaciones.prescripcion = pres;
    this.modalFacturaciones.eps = this.eps;
    this.modalFacturaciones.regimen = this.regimen;
    this.modalFacturaciones.tutela = false;
    this.showModalFacturaciones.nativeElement.click();
    this.modalFacturaciones.showModal();
  }

  detalleFacturacion(data: any) {
    this.modalFacturaciones.hideModal();
    this.modalFacturacion.facturacion = data.fact;
    this.modalFacturacion.prescripcion = data.presc;
    this.modalFacturacion.homologo = data.homologo;
    this.modalFacturacion.informacion = data.informacion;
    this.modalFacturacion.eps = this.eps;
    this.modalFacturacion.regimen = this.regimen;
    this.showModalFacturacion.nativeElement.click();
    this.modalFacturacion.showModal();
  }

  datosFacturados(presc: PrescripcionApi) {
    this.datosFacturadosGenerico(presc.prescripcion.NoPrescripcion);
  }

  datosFacturadosGenerico(pres: string) {
    this.modalDatosFacturacion.prescripcion = pres;
    this.modalDatosFacturacion.eps = this.eps;
    this.modalDatosFacturacion.regimen = this.regimen;
    this.modalDatosFacturacion.tutela = false;
    this.showModalDatosFacturacion.nativeElement.click();
    this.modalDatosFacturacion.showModal();
  }

  auditarPrescripcion(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalAuditarPrescripcion.prescripcion = data.presc;
    this.modalAuditarPrescripcion.auditoria = data.salida;
    this.showModalAuditar.nativeElement.click();
    this.modalAuditarPrescripcion.showModal();
  }

  actualizarPrescripcion(data: any) {
    for (var i in this.prescripciones) {
      let pres = this.prescripciones[i];
      if (
        pres.prescripcion.NoPrescripcion ==
        data.presc.prescripcion.NoPrescripcion
      ) {
        this.prescripciones[i] = data.presc;
      }
    }

    this.modalPrescripcion.prescripcion = data.presc;
    this.showModalPrescripcion.nativeElement.click();
    this.modalPrescripcion.showModal();
  }

  detalleMedicamento(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalMedicamento.medicamento = data.med;
    this.modalMedicamento.prescripcion = data.presc;
    this.modalMedicamento.showModal();
  }

  detalleServicioCompl(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalServicioCompl.servicioCompl = data.serv;
    this.modalServicioCompl.prescripcion = data.presc;
    this.modalServicioCompl.showModal();
  }

  detalleProcedimiento(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalProcedimiento.procedimiento = data.proc;
    this.modalProcedimiento.prescripcion = data.presc;
    this.modalProcedimiento.showModal();
  }

  detalleProductoNutri(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalProductoNutric.productoNutr = data.prod;
    this.modalProductoNutric.prescripcion = data.presc;
    this.modalProductoNutric.showModal();
  }

  detalleDispositivoMedic(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalDispositivoMedic.dispositivo = data.dips;
    this.modalDispositivoMedic.prescripcion = data.presc;
    this.modalDispositivoMedic.showModal();
  }

  direccionamientoAgregar(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalDireccionamientoAdd.eps = this.eps;
    this.modalDireccionamientoAdd.regimen = this.regimen;
    this.modalDireccionamientoAdd.salida = data.salida;
    this.modalDireccionamientoAdd.prescripcion = data.presc;
    this.modalDireccionamientoAdd.medicamento = data.medicamento;
    this.modalDireccionamientoAdd.informacion = data.informacion;
    this.modalDireccionamientoAdd.tarifa = data.tarifa;
    this.modalDireccionamientoAdd.tutelaAfiliado = data.tutelaAfiliado;
    this.modalDireccionamientoAdd.proveedorSeleccionado = data.proveedor;
    this.modalDireccionamientoAdd.showModal();
  }

  mostrarModalBuscarTarifas(data: any) {
    this.modalDireccionamientoAdd.hideModal(false);
    this.modalBuscarTarifas.eps = this.eps;
    this.modalBuscarTarifas.regimen = this.regimen;
    this.modalBuscarTarifas.proveedor = data.prov;
    this.modalBuscarTarifas.prescripcion = data.presc;
    this.modalBuscarTarifas.tutela = data.tut;
    this.modalBuscarTarifas.direccionamiento = data.direccionamiento;
    this.modalBuscarTarifas.showModal();
  }

  mostrarModalBuscarTutelas(data: any) {
    this.modalDireccionamientoAdd.hideModal(false);
    this.modalBuscarTutelas.eps = this.eps;
    this.modalBuscarTutelas.regimen = this.regimen;
    this.modalBuscarTutelas.proveedor = data.prov;
    this.modalBuscarTutelas.prescripcion = data.presc;
    this.modalBuscarTutelas.tutela = data.tut;
    this.modalBuscarTutelas.direccionamiento = data.direccionamiento;
    this.modalBuscarTutelas.showModal();
  }

  mostrarModalBuscarHomologos(data: any) {
    this.modalFacturacion.hideModal(false);
    this.modalBuscarHomologos.eps = this.eps;
    this.modalBuscarHomologos.regimen = this.regimen;
    this.modalBuscarHomologos.prescripcion = data.prescripcion;
    this.modalBuscarHomologos.facturacion = data.facturacion;
    this.modalBuscarHomologos.informacion = data.informacion;
    this.modalBuscarHomologos.showModal();
  }

  noDireccionamientoAgregar(data: any) {
    this.modalPrescripcion.hideModal();
    this.modalNoDireccionamientoAdd.eps = this.eps;
    this.modalNoDireccionamientoAdd.regimen = this.regimen;
    this.modalNoDireccionamientoAdd.salida = data.salida;
    this.modalNoDireccionamientoAdd.prescripcion = data.presc;
    this.modalNoDireccionamientoAdd.showModal();
  }

  mostrarModalFacturaRips(data: any) {
    this.modalFacturaRips.prescripcion = data.prescripcion;
    this.modalFacturaRips.datoFact = data.datoFact;
    this.modalFacturaRips.eps = this.eps;
    this.modalFacturaRips.regimen = this.regimen;
    this.modalFacturaRips.tutela = false;
    this.showModalFacturaRips.nativeElement.click();
    this.modalFacturaRips.showModal();
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
