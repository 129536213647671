import { AuditoriaRelApi } from "./../models/containers/auditoria-rel-api.model";
import { AuditoriaSalida } from "./../models/salida/auditoria-salida.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class AuditoriaService {
  baseUrl = API_REST + "auditoria/";

  constructor(private http: HttpClient) {}

  auditar(salida: AuditoriaSalida) {
    return this.http.post<AuditoriaRelApi>(this.baseUrl, salida);
  }

  actualizarAuditoria(salida: AuditoriaSalida) {
    return this.http.put<AuditoriaRelApi>(this.baseUrl, salida);
  }

  xlsxReporteDetalles(nit: string, regimen: string, inicio: string,
    fin: string, identificacion: string, numero: string, tutela: boolean) {
    return this.http.get(
      `${this.baseUrl}xlsx-dirs/${nit}/${regimen}/${inicio}/${fin}/${identificacion}/${numero}/${tutela}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
