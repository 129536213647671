import { AuditoriaOrdenSalida } from './auditoria-orden-salida.model';
export class AuditoriaSalida {
  numPrescripcion: string;
	tipoIdPaciente: string;
	numIdPaciente: string;
	tutela: boolean;
	auditada: boolean;
  descripcion: string;
  ordenes: AuditoriaOrdenSalida[];
}
