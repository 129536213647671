import { Eps } from "./../../models/eps.model";
import { NAME_APP } from "./../../url.constants";
import { Title } from "@angular/platform-browser";
import { ApiService } from "./../../services/api.service";
import { Component, OnInit } from "@angular/core";
import * as moment from "moment";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Regimen } from "src/app/models/regimen.model";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import Swal from "sweetalert2";

@Component({
  selector: 'app-main-informe-autorizaciones',
  templateUrl: './main-informe-autorizaciones.component.html',
  styleUrls: ['./main-informe-autorizaciones.component.scss']
})
export class MainInformeAutorizacionesComponent implements OnInit {
  now = moment();
  momento = moment;
  filtrado: string = "f";
  maxDias: number = 14;
  tipoBusqueda: number = 1;
  
  tipos = TIPOS_DOCUMENTOS;

  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFin: string = this.fechaInicio;
  tipoReporte: number = 0;
  identificacion: string = "";
  tipoId: string = this.tipos[0].value;
  numero: string = "";
  estados: string = "0-1-1";

  eps: Eps = null;
  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Reporte detallado`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  cambiarFiltro(f: string) {
    this.filtrado = f;
    this.identificacion = "";
    this.numero = "";
  }

  busqueda() {
    Swal.fire({
      title: "Generando archivo ... ",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    let nombreReporte =
      "informe-autorizaciones-" +
      (this.tipoReporte ? "tut" : "presc") +
      "-" +
      this.regimen.codigo;

    switch (this.filtrado) {
      case "f":
        nombreReporte += "-" + this.fechaInicio + "_" + this.fechaFin;
        break;
      case "p":
        nombreReporte += "-" + this.identificacion;
        break;
      case "n":
        nombreReporte += "-" + this.numero;
        break;
    }

    this.estados = '0-1-1';
    if (this.identificacion.trim().length == 0) {
      this.identificacion = "null";
    }
    if (this.numero.trim().length == 0) {
      this.numero = "null";
    }
    switch (parseInt(this.tipoReporte + "")) {
      case 0:
        this.apiService.informeAutorizacionesService
          .xlsxInformePrescripciones(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero,
            this.estados
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 1:
        this.apiService.informeAutorizacionesService
          .xlsxInformeTutelas(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero,
            this.estados
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 2:        
        this.apiService.informeAutorizacionesService
          .xlsxInformeFacturacionPrescripciones(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      case 3:
        this.apiService.informeAutorizacionesService
          .xlsxInformeFacturacionTutelas(
            this.tipoBusqueda,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaInicio,
            this.fechaFin,
            this.identificacion,
            this.tipoId,
            this.numero
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                nombreReporte,
                "xlsx"
              );
            },
            error => {
              console.log(error);
              this.apiService.notifService.error("Error", error);
              Swal.close();
            }
          );
        break;
      default:
        Swal.close();
        break;
    }
  }

}
