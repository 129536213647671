import { Component, OnInit, Input, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Suministro } from 'src/app/models/msumin/suministro.model';

@Component({
  selector: 'app-modal-suministro-detalle',
  templateUrl: './modal-suministro-detalle.component.html',
  styleUrls: ['./modal-suministro-detalle.component.scss']
})
export class ModalSuministroDetalleComponent implements OnInit {
  @Input() public suministro: Suministro;
  @Input() public prescripcion: string;
  @Output() public showModalSuministros = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    if (this.prescripcion) {
      this.showModalSuministros.emit(this.prescripcion);
    }
  }

}
