import { Injectable } from '@angular/core';
import { API_REST_IUM } from '../url.constants';
import axios from "axios";

@Injectable({
  providedIn: 'root'
})
export class IumService {

  constructor() { }

  getByNombreComercialOrPrincipioActivoOrIum(nombrecomercial: string,
    principioactivo: string, ium: string) {

    let url = API_REST_IUM;

    if (ium && ium.trim().length>0) {
      url+=`?$where=ium%20like%20%27%25${ium}%25%27`;
    } else if (nombrecomercial && nombrecomercial.trim().length>0) {
      url+=`?$where=nombre_comercial_%20like%20%27%25${nombrecomercial}%25%27`;
    } else if (principioactivo && principioactivo.trim().length>0) {
      url+=`?$where=principio_activo1%20like%20%27%25${principioactivo}%25%27`;
    }
    url+=`&$order=nombre_comercial_`;
    return axios.get(url);
  }
}
