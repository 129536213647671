import { MensajesAnulacionApi } from "./../models/containers/mensajes-anulacion-api.model";
import { SuministroAnularSalida } from "./../models/salida/suministro-anular-salida.model";
import { IdentificadoresSuministroApi } from "./../models/containers/identificadores-suministro-api.model";
import { SuministroSalida } from "./../models/salida/suministro-salida.model";
import { SuministrosApi } from "./../models/containers/suministros-api.model";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SuministrosService {
  baseUrl = API_REST + "suministro/";

  constructor(private http: HttpClient) {}

  byPrescripcion(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get<SuministrosApi>(
      `${this.baseUrl}byPrescripcion/false/${nit}/${regimen}/${numPrescripcion}`
    );
  }

  byTutela(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get<SuministrosApi>(
      `${this.baseUrl}byPrescripcion/true/${nit}/${regimen}/${numPrescripcion}`
    );
  }

  xlsxPrescripcion(nit: string, regimen: string, numPrescripcion: string) {
    return this.http.get(
      `${this.baseUrl}byPrescripcion/true/${nit}/${regimen}/${numPrescripcion}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  getPorFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<SuministrosApi>(
      `${this.baseUrl}byFecha/true/${nit}/${regimen}/${fecha}`
    );
  }

  xlsxPorFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get(
      `${this.baseUrl}byFecha/true/${nit}/${regimen}/${fecha}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  getPorPaciente(
    tipoDoc: string,
    numDoc: string,
    nit: string,
    regimen: string,
    fecha: string
  ) {
    return this.http.get<SuministrosApi>(
      `${this.baseUrl}byPacienteFecha/true/${nit}/${regimen}/${fecha}/${tipoDoc}/${numDoc}`
    );
  }

  xlsxPorPaciente(
    tipoDoc: string,
    numDoc: string,
    nit: string,
    regimen: string,
    fecha: string
  ) {
    return this.http.get(
      `${this.baseUrl}byPacienteFecha/true/${nit}/${regimen}/${fecha}/${tipoDoc}/${numDoc}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  enviar(nit: string, regimen: string, salida: SuministroSalida) {
    return this.http.post<IdentificadoresSuministroApi>(
      `${this.baseUrl}enviar/${nit}/${regimen}`,
      salida
    );
  }

  anular(nit: string, regimen: string, salida: SuministroAnularSalida) {
    return this.http.post<MensajesAnulacionApi>(
      `${this.baseUrl}anular/${nit}/${regimen}`,
      salida
    );
  }
}
