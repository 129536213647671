import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter
} from "@angular/core";
import { TutelaApi } from "src/app/models/mpresc/tutela-api.model";
import { ProcedimientoTut } from "src/app/models/mpresc/procedimiento-tut.model";

@Component({
  selector: "app-modal-procedimiento-tut-detalle",
  templateUrl: "./modal-procedimiento-tut-detalle.component.html",
  styleUrls: ["./modal-procedimiento-tut-detalle.component.scss"]
})
export class ModalProcedimientoTutDetalleComponent implements OnInit {
  @Input() public procedimiento: ProcedimientoTut;
  @Input() public tutela: TutelaApi;
  @Output() public showModalTutela = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalTutela.emit(this.tutela);
  }
}
