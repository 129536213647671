import { JwtResponse } from './../../auth/jwt-response';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import * as $ from 'jquery';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  info: JwtResponse = null;

  constructor(
    private token: TokenStorageService,
    private router: Router) {
  }

  ngOnInit() {
    this.token.validate();
    this.info = this.token.getInfo();
  }

  logout() {
    this.token.signOut();
    this.router.navigate(['/inicio']);
  }

  sidebarToggle() {
    $("body").toggleClass("sidebar-toggled");
    $(".sidebar").toggleClass("toggled");
  }
}
