import { ApiService } from "./../../../services/api.service";
import { TokenPresc } from "./../../../models/token-presc.model";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "app-modal-token-edit",
  templateUrl: "./modal-token-edit.component.html",
  styleUrls: ["./modal-token-edit.component.scss"]
})
export class ModalTokenEditComponent implements OnInit {
  @Input() public token: TokenPresc;
  @Output() public UpdateToken = new EventEmitter();

  show: boolean = false;

  // Administrar regimen de la eps
  tokenForm: FormGroup;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.token) {
      this.tokenForm = this.fb.group({
        token: [this.token.token, Validators.required]
      });
    }
  }

  showModal() {
    this.show = true;
    this.ngOnInit();
  }

  hideModal() {
    this.show = false;
    this.ngOnInit();
  }

  onSubmit() {
    let tokenNuevo = this.tokenForm.get("token").value;

    this.apiService.tokenService
      .updateToken(this.token.regimen.codigo, tokenNuevo)
      .subscribe(data => {
        if (data.token) {
          this.token.token = data.token.token;
          this.UpdateToken.emit(this.token);
        }
      });
  }
}
