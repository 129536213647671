import { ApiService } from "./../../../services/api.service";
import { DatoFacturado } from "./../../../models/mfactu/dato-facturado.model";
import {Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import { DatoFacturadoAnularSalida } from "src/app/models/mfactu/dato-facturado-anular-salida.model";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";

@Component({
  selector: 'app-modal-datos-facturados-detalle',
  templateUrl: './modal-datos-facturados-detalle.component.html',
  styleUrls: ['./modal-datos-facturados-detalle.component.scss']
})
export class ModalDatosFacturadosDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  datos: DatoFacturado[];
  cargando: boolean = false;

  @Output() public showModalFacturaRips = new EventEmitter();

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.datos = [];
      this.cargando = true;
      this.apiService.datosFacturadosService
        .byPrescripcion(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion
        )
        .subscribe(
          data => {
            if (data.datos) {
              this.datos = data.datos;
            }
            this.cargando = false;
          },
          error => {
            this.apiService.notifService.error("Error", error);
            this.cargando = false;
          }
        );
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  vincularFactura(dir: DatoFacturado) {
    this.showModalFacturaRips.emit({"datoFact": dir, "prescripcion": this.prescripcion})
  }

  anular(dir: DatoFacturado) {
    let entrada = new DatoFacturadoAnularSalida();

    entrada.idDatoFacturado = dir.IDDatosFacturado;
    entrada.idFactura = dir.ID;
    entrada.numPrescripcion = dir.NoPrescripcion;

    console.log(entrada);
    this.apiService.datosFacturadosService
      .anular(this.eps.identificacion, this.regimen.codigo, this.prescripcion, entrada)
      .subscribe(
        data => {
          this.cargando = true;
          setTimeout(() => this.cargarInformacion(), 1500);
        },
        error => {
          this.apiService.notifService.error("Error", error);
        }
      );
  }

}
