import { AuditoriaOrdenSalida } from './../../../models/salida/auditoria-orden-salida.model';
import { TutelaApi } from "src/app/models/mpresc/tutela-api.model";
import { Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ApiService } from "./../../../services/api.service";
import { AuditoriaSalida } from "./../../../models/salida/auditoria-salida.model";
import { PrescripcionApi } from "./../../../models/mpresc/prescripcion-api.model";
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  EventEmitter
} from "@angular/core";

@Component({
  selector: "app-modal-auditar-prescripcion",
  templateUrl: "./modal-auditar-prescripcion.component.html",
  styleUrls: ["./modal-auditar-prescripcion.component.scss"]
})
export class ModalAuditarPrescripcionComponent implements OnInit {
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public auditoria: AuditoriaSalida;
  @Output() public showModalPrescripcion = new EventEmitter();
  @Output() public showModalTutela = new EventEmitter();
  @Output() public actualizarPrescripcion = new EventEmitter();
  @Output() public actualizarTutela = new EventEmitter();

  auditoriaForm: FormGroup = null;

  ordenes: AuditoriaOrdenSalida[] = null;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
  }

  showModal() {
    this.auditoriaForm = this.fb.group({
      descripcion: [""]
    });

    if(this.prescripcion) {
      this.llenarAuditoriasOrdenesPresc();
    }
    if(this.tutela) {
      this.llenarAuditoriasOrdenesTut();
    }
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    if (this.auditoria.tutela) {
      this.showModalTutela.emit(this.tutela);
    } else {
      this.showModalPrescripcion.emit(this.prescripcion);
    }
  }

  llenarAuditoriasOrdenesPresc() {
    this.ordenes = new Array();

    try {
      this.prescripcion.medicamentos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'M', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.prescripcion.procedimientos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'P', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.prescripcion.productosnutricionales.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'N', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.prescripcion.dispositivos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'D', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.prescripcion.serviciosComplementarios.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'S', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }
  }

  llenarAuditoriasOrdenesTut() {
    this.ordenes = new Array();

    try {
      this.tutela.medicamentos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'M', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.tutela.procedimientos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'P', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.tutela.productosnutricionales.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'N', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.tutela.dispositivos.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'D', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }

    try {
      this.tutela.serviciosComplementarios.forEach(element => {
        this.ordenes.push(new AuditoriaOrdenSalida(element.ConOrden, 'S', true, '', 1, element.TipoPrest));
      });
    } catch (error) {
    }
  }

  onSubmit() {
    let { descripcion } = Object.assign({}, this.auditoriaForm.value);

    let auditoriaEdit = new AuditoriaSalida();

    auditoriaEdit.numPrescripcion = this.auditoria.numPrescripcion;
    auditoriaEdit.tutela = this.auditoria.tutela;
    auditoriaEdit.tipoIdPaciente = this.auditoria.tipoIdPaciente;
    auditoriaEdit.numIdPaciente = this.auditoria.numIdPaciente;

    auditoriaEdit.descripcion = descripcion;

    auditoriaEdit.ordenes = this.ordenes;

    this.apiService.auditoriaService.auditar(auditoriaEdit).subscribe(
      data => {
        this.modalClose.nativeElement.click();
        if (this.auditoria.tutela) {
          this.tutela.auditoria = data.auditoria;
          this.updateTutAuditoria();
          this.actualizarTutela.emit({ tut: this.tutela });
        } else {
          this.prescripcion.auditoria = data.auditoria;
          this.updatePrescAuditoria();
          this.actualizarPrescripcion.emit({ presc: this.prescripcion });
        }
      },
      error => {
        this.apiService.notifService.error("Error", error);
      }
    );
  }

  updatePrescAuditoria() {
    this.prescripcion.auditoria.ordenes.forEach(orden => {
      switch (orden.tipoTecnologia) {
        case "M":
          this.prescripcion.medicamentos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "P":
          this.prescripcion.procedimientos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "N":
          this.prescripcion.productosnutricionales.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "D":
          this.prescripcion.dispositivos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "S":
          this.prescripcion.serviciosComplementarios.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
      }
    });
  }

  updateTutAuditoria() {
    this.tutela.auditoria.ordenes.forEach(orden => {
      switch (orden.tipoTecnologia) {
        case "M":
          this.tutela.medicamentos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "P":
          this.tutela.procedimientos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "N":
          this.tutela.productosnutricionales.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "D":
          this.tutela.dispositivos.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
        case "S":
          this.tutela.serviciosComplementarios.forEach(item => {
            if (orden.orden == item.ConOrden) {
              item.rel.auditoria = orden;
            }
          });
          break;
      }
    });
  }
}
