import { ApiService } from './../../../services/api.service';
import { Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Eps } from 'src/app/models/eps.model';
import { Regimen } from 'src/app/models/regimen.model';
import { Facturacion } from 'src/app/models/mfactu/facturacion-model';

@Component({
  selector: 'app-modal-facturaciones-detalle',
  templateUrl: './modal-facturaciones-detalle.component.html',
  styleUrls: ['./modal-facturaciones-detalle.component.scss']
})
export class ModalFacturacionesDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;
  @Output() public showModalFacturacion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  facturaciones: Facturacion[];
  cargando: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.facturaciones = [];
      this.cargando = true;
      this.apiService.facturacionesService
        .byPrescripcion(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion
        )
        .subscribe(
          data => {
            if (data.facturaciones) {
              this.facturaciones = data.facturaciones;
            }
            this.cargando = false;
          },
          error => {
            this.apiService.notifService.error("Error", error);
            this.cargando = false;
          }
        );
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleFacturacion(rep: Facturacion) {
    this.showModalFacturacion.emit({ fact: rep, presc: this.prescripcion});
  }

}
