import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";
import { NAME_APP } from "src/app/url.constants";
import { ModalSuministroDetalleComponent } from "../modals/modal-suministro-detalle/modal-suministro-detalle.component";
import { Suministro } from "src/app/models/msumin/suministro.model";
import * as moment from "moment";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { SuministroAnularSalida } from 'src/app/models/salida/suministro-anular-salida.model';

@Component({
  selector: 'app-main-suministros',
  templateUrl: './main-suministros.component.html',
  styleUrls: ['./main-suministros.component.scss']
})
export class MainSuministrosComponent implements OnInit {

  now = moment();
  filtrado: string = "f";
  fecha: string = this.now.format("YYYY-MM-DD");
  fechaAfiliado: string = this.now.format("YYYY-MM-DD");
  tipoDocumento: string = "CC";
  documento: string;
  prescripcion: string;
  primera: boolean = false;
  cargando: boolean = false;

  suministros: Suministro[] = [];

  tipos = TIPOS_DOCUMENTOS;

  epss: Eps[] = [];
  eps: Eps = null;

  regimen: Regimen = null;

  @ViewChild("modalSuministro", { static: false })
  modalSuministro: ModalSuministroDetalleComponent;

  @ViewChild("showModalSuministro", { static: false })
  showModalSuministro: ElementRef;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Prescripciones`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
    
  }

  cambiarFiltro(f: string) {
    this.suministros = [];
    this.filtrado = f;
  }

  busqueda() {
    this.suministros = [];
    this.cargando = true;

    switch (this.filtrado) {
      case "f":
        this.apiService.suministrosService
          .getPorFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              if (data.suministros) {
                this.suministros = data.suministros;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "a":
        this.apiService.suministrosService
          .getPorPaciente(
            this.tipoDocumento,
            this.documento,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaAfiliado
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              if (data.suministros) {
                this.suministros = data.suministros;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "p":
        this.apiService.suministrosService
          .byPrescripcion(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              if (data.suministros) {
                this.suministros = data.suministros;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );
        break;
    }
  }

  busquedaExcel() {
    this.suministros = [];
    this.cargando = true;

    switch (this.filtrado) {
      case "f":
        this.apiService.suministrosService
          .xlsxPorFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                "Suministros",
                "xlsx"
              );
              this.cargando = false;
              this.primera = true;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "a":
        this.apiService.suministrosService
          .xlsxPorPaciente(
            this.tipoDocumento,
            this.documento,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaAfiliado
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                "Suministros",
                "xlsx"
              );
              this.cargando = false;
              this.primera = true;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "p":
        this.apiService.suministrosService
          .xlsxPrescripcion(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            response => {
              this.apiService.utilService.downloadFile(
                response,
                "Suministros",
                "xlsx"
              );
              this.cargando = false;
              this.primera = true;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );
        break;
    }
  }

  detalleSuministro(rep: Suministro) {
    this.modalSuministro.suministro = rep;
    this.modalSuministro.prescripcion = rep.NoPrescripcion;
    this.showModalSuministro.nativeElement.click();
    this.modalSuministro.showModal();
  }

  anular(dir: Suministro) {
    let entrada = new SuministroAnularSalida();

    entrada.conTec = dir.ConTec;
    entrada.idNoSuministro = dir.IDSuministro;
    entrada.id = dir.ID;
    entrada.numPrescripcion = dir.NoPrescripcion;
    entrada.tipoTec = dir.TipoTec;

    this.apiService.suministrosService
      .anular(this.eps.identificacion, this.regimen.codigo, entrada)
      .subscribe(
        data => {
          console.log(data);
          this.suministros.forEach(sum => {
            if (sum.IDSuministro == entrada.idNoSuministro) {
              sum.EstSuministro = 0;
            }
          });
        },
        error => {
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
