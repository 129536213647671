import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Select2OptionData } from "ng2-select2";
import { Observable } from "rxjs";
import { DciApi } from 'src/app/models/containers/dci-api.model';
import { Eps } from "src/app/models/eps.model";
import { Facturacion } from "src/app/models/mfactu/facturacion-model";
import { ReporteEntrega } from 'src/app/models/msumin/reporte-entrega.model';
import { Regimen } from "src/app/models/regimen.model";
import Swal from 'sweetalert2';
import { Direccionamiento } from "../../../models/msumin/direccionamiento.model";
import {
  COMPARADOR_FACTURACION,
  UNIDAD_CONCENTRACION_COMPARADOR
} from "./../../../data/datos.constants";
import { ComparadorAdministrativo } from "./../../../models/containers/comparador-administrativo.model";
import { DatoFacturadoSalida } from "./../../../models/mfactu/dato-facturado-salida.model";
import { ApiService } from "./../../../services/api.service";

@Component({
  selector: 'app-modal-facturacion-detalle',
  templateUrl: './modal-facturacion-detalle.component.html',
  styleUrls: ['./modal-facturacion-detalle.component.scss']
})
export class ModalFacturacionDetalleComponent implements OnInit {
  @Input() public facturacion: Facturacion;
  @Input() public homologo: DciApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public informacion: any;
  @Input() public es_tutela: boolean;
  @Output() public showModalFacturaciones = new EventEmitter();
  @Output() public showModalBuscarHomologos = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  datoFacturadoForm: FormGroup;
  datoFacturadoSalida: DatoFacturadoSalida;
  comparadorSelect: any = null;
  comparadores = COMPARADOR_FACTURACION;

  enviandoForm: boolean = false;

  selectOptions: Select2Options;

  dciData: Observable<Array<Select2OptionData>>;
  CodHomSelect: any = null;

  unidadesDispData: Observable<Array<Select2OptionData>>;
  UniDispHomSelect: any = null;

  CodCompAdmSelect: ComparadorAdministrativo = null;

  UniCompAdmSelect: any = null;

  mostrarFormDato: boolean = false;

  compsAdmList: ComparadorAdministrativo[] = [];

  reporteEntrega: ReporteEntrega = null;

  direccionamiento: Direccionamiento = null;

  unidadesCompAdm = UNIDAD_CONCENTRACION_COMPARADOR;

  valorPagar = "0.000"

  constructor(private apiService: ApiService, private fb: FormBuilder) {}

  ngOnInit() {
    this.selectOptions = {
      placeholder: "Select option...",
      allowClear: true,
      width: "100%"
    };
    this.comparadorSelect = this.comparadores[2];
    this.datoFacturadoForm = this.fb.group({
      ValUnMiCon: ["0"],
      CantTotEnt: ["0"],
      ValTotCompAdm: ["0"],
      ValTotHom: ["0"],
      numeroFactura: ["", Validators.required],
      nitPrestador: [""],
    });

    if (this.informacion) {
      this.datoFacturadoForm.patchValue({
        ValUnMiCon: this.informacion.ValUnMiCon,
        CantTotEnt: this.informacion.CantTotEnt,
        ValTotCompAdm: this.informacion.ValTotCompAdm,
        ValTotHom: this.informacion.ValTotHom,
        numeroFactura: this.informacion.numeroFactura,
        nitPrestador: this.informacion.nitPrestador,
      })

      this.comparadorSelect = this.informacion.comparadorSelect
      this.CodCompAdmSelect = this.informacion.CodCompAdmSelect
      this.UniCompAdmSelect= this.informacion.UniCompAdmSelect
      this.UniDispHomSelect = this.informacion.UniDispHomSelect
    }
    this.CodHomSelect = this.homologo ? this.homologo.codigo: null

    this.apiService.codigosMipresService
      .getComparadoresAdministrativo()
      .subscribe(data => {
        this.compsAdmList = data;
      });

    if (this.facturacion) {
      // cargar reportes de entrega
      this.apiService.reportesEntregasService.byNumero(this.eps.identificacion, this.regimen.codigo, this.facturacion.NoPrescripcion)
      .subscribe(data => {
        this.reporteEntrega = this.apiService.reportesEntregasService.filtroReporteEntrega(data.reportes,
          this.facturacion.TipoTec, this.facturacion.ConTec, this.facturacion.NoEntrega)

      })
      // cargar datos de direccionamiento
      if (this.es_tutela) {
        this.apiService.direccionamientosService.getPrescripcionTutel(this.eps.identificacion, this.regimen.codigo, this.facturacion.NoPrescripcion)
        .subscribe(data => {
          this.direccionamiento = this.apiService.direccionamientosService.filtroDireccionamiento(data.direccionamientos,
            this.facturacion.TipoTec, this.facturacion.ConTec, this.facturacion.NoEntrega)
            if (this.direccionamiento && this.direccionamiento.NoIDProv) {
              this.datoFacturadoForm.patchValue({
                'nitPrestador': this.direccionamiento.NoIDProv
              })
            }
        })
      } else {
        this.apiService.direccionamientosService.getPrescripcionPresc(this.eps.identificacion, this.regimen.codigo, this.facturacion.NoPrescripcion)
        .subscribe(data => {
          this.direccionamiento = this.apiService.direccionamientosService.filtroDireccionamiento(data.direccionamientos,
            this.facturacion.TipoTec, this.facturacion.ConTec, this.facturacion.NoEntrega)
            if (this.direccionamiento && this.direccionamiento.NoIDProv) {
              this.datoFacturadoForm.patchValue({
                'nitPrestador': this.direccionamiento.NoIDProv
              })
            }
        })
      }
      try {
        this.valorPagar = (parseFloat(this.facturacion.ValorTotFacturado) - parseFloat(this.facturacion.Copago) - parseFloat(this.facturacion.CuotaModer)).toFixed(3)
      } catch(e) {
      }
    }
    let idUnidadDisp = this.informacion ? this.informacion.idUnidadDisp: null;
    this.unidadesDispData = this.apiService.codigosMipresService.getUnidadesDispensacionDynamicList(idUnidadDisp);

    //this.dciData = this.apiService.codigosMipresService.getDciDynamicList();
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal(open: boolean = true) {
    this.modalClose.nativeElement.click();
    if (open) {
      if (this.showModalFacturaciones) {
        this.showModalFacturaciones.emit(this.prescripcion);
      }
    }
  }

  onSubmit() {
    this.datoFacturadoSalida = Object.assign({}, this.datoFacturadoForm.value);

    this.datoFacturadoSalida.ID = this.facturacion.ID;
    this.datoFacturadoSalida.CodCompAdm = this.CodCompAdmSelect
      ? this.CodCompAdmSelect.codigo
      : null;
    this.datoFacturadoSalida.CodHom = this.CodHomSelect;
    this.datoFacturadoSalida.CompAdm = this.comparadorSelect
      ? this.comparadorSelect.value
      : null;
    this.datoFacturadoSalida.UniCompAdm = this.UniCompAdmSelect
      ? this.UniCompAdmSelect.value
      : null;
    this.datoFacturadoSalida.UniDispHom = this.UniDispHomSelect
      ? this.UniDispHomSelect.value
      : null;

    if (
      this.datoFacturadoSalida.CompAdm &&
      this.datoFacturadoSalida.CompAdm.toString() == "3"
    ) {
      this.datoFacturadoSalida.CodHom = null;
      this.datoFacturadoSalida.CodCompAdm = null;
      this.datoFacturadoSalida.ValUnMiCon = null;
      this.datoFacturadoSalida.ValTotHom = null;
      this.datoFacturadoSalida.ValTotCompAdm = null;
      this.datoFacturadoSalida.CantTotEnt = null;
      this.datoFacturadoSalida.UniCompAdm = null;
      this.datoFacturadoSalida.UniDispHom = null;
    }

    if (
      this.datoFacturadoSalida.CompAdm &&
      this.datoFacturadoSalida.CompAdm.toString() == "2"
    ) {
      this.datoFacturadoSalida.ValTotCompAdm = null;
      this.datoFacturadoSalida.UniCompAdm = null;
      this.datoFacturadoSalida.CodCompAdm = null;
    }
    if (
      this.datoFacturadoSalida.CompAdm &&
      this.datoFacturadoSalida.CompAdm.toString() == "1"
    ) {
      this.datoFacturadoSalida.ValTotHom = null;
      this.datoFacturadoSalida.UniDispHom = null;
      this.datoFacturadoSalida.CodHom = null;
    }

    if (!this.datoFacturadoSalida.numeroFactura || this.datoFacturadoSalida.numeroFactura.trim().length==0) {
      Swal.fire("Error", "Debe ingresar el número de factura.");
      return;
    }

    if (!this.datoFacturadoSalida.nitPrestador || this.datoFacturadoSalida.nitPrestador.trim().length==0) {
      Swal.fire("Error", "Debe ingresar el nit del prestador.");
      return;
    }

    if (this.facturacion.NoFactura == null || this.facturacion.NoFactura.trim().length<30) {
      Swal.fire("Error", "La factura debe ser CUFE, sino es así no puede realizar Datos Facturados, avise al proveedor para que realice el ajuste.");
      return;
    }

    this.datoFacturadoSalida.codTec = this.facturacion.ConTec
    this.datoFacturadoSalida.tipoTec = this.facturacion.TipoTec
    this.datoFacturadoSalida.numEntrega = this.facturacion.NoEntrega
    this.datoFacturadoSalida.numIdentificacion = this.facturacion.NoIDPaciente
    if (this.reporteEntrega) {
      this.datoFacturadoSalida.idProceso = this.reporteEntrega.ID
    }

    this.enviandoForm=true;
    this.apiService.datosFacturadosService
      .enviar(
        this.eps.identificacion,
        this.regimen.codigo,
        this.prescripcion,
        this.datoFacturadoSalida
      )
      .subscribe(
        data => {
          this.enviandoForm=false;
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El dato facturado se ha generado"
          });
          setTimeout(() => this.hideModal(), 1500);
        },
        error => {
          this.enviandoForm=false;
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  buscarTarifas() {
    let informacion = {
      ValUnMiCon: this.datoFacturadoForm.value.ValUnMiCon,
      CantTotEnt: this.datoFacturadoForm.value.CantTotEnt,
      ValTotCompAdm: this.datoFacturadoForm.value.ValTotCompAdm,
      ValTotHom: this.datoFacturadoForm.value.ValTotHom,
      comparadorSelect: this.comparadorSelect,
      CodCompAdmSelect: this.CodCompAdmSelect,
      CodHomSelect: this.CodHomSelect,
      UniCompAdmSelect: this.UniCompAdmSelect,
      numeroFactura: this.datoFacturadoForm.value.numeroFactura,
      UniDispHomSelect: this.UniDispHomSelect
    };
    this.showModalBuscarHomologos.emit({prescripcion: this.prescripcion, facturacion: this.facturacion,
      informacion: informacion});
  }

}
