import { ApiService } from './../../../services/api.service';
import { NoDireccionamientoSalida } from './../../../models/salida/no-direccionamiento-salida.model';
import { Usuario } from './../../../models/usuario.model';
import { AuditoriaSalida } from './../../../models/salida/auditoria-salida.model';
import { DireccionamientoSalida } from "./../../../models/salida/direccionamiento-salida.model";
import { DispositivoMedico } from "./../../../models/mpresc/dispositivo-medico.model";
import { ProductoNutricional } from "./../../../models/mpresc/producto-nutricional.model";
import { Procedimiento } from "./../../../models/mpresc/procedimiento.model";
import { ServicioComplementario } from "./../../../models/mpresc/servicio-complementario.model";
import { Medicamento } from "./../../../models/mpresc/medicamento.model";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Eps } from 'src/app/models/eps.model';
import { Regimen } from 'src/app/models/regimen.model';
import Swal from 'sweetalert2';

@Component({
  selector: "app-modal-prescripcion-detalle",
  templateUrl: "./modal-prescripcion-detalle.component.html",
  styleUrls: ["./modal-prescripcion-detalle.component.scss"]
})
export class ModalPrescripcionDetalleComponent implements OnInit {
  @Input() public prescripcion: PrescripcionApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;

  @Output() public showModalMedicamento = new EventEmitter();
  @Output() public showModalServicio = new EventEmitter();
  @Output() public showModalProcedimiento = new EventEmitter();
  @Output() public showModalProducto = new EventEmitter();
  @Output() public showModalDispositivo = new EventEmitter();

  @Output() public showModalDireccionamientoAdd = new EventEmitter();
  @Output() public showModalNoDireccionamientoAdd = new EventEmitter();
  @Output() public showModalAuditoria = new EventEmitter();
  
  @Output() public showModalBuscarTarifas = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  filtrado: string;

  estadoAuditoria: string = null;

  esAuditable: boolean = true;

  constructor(private token: TokenStorageService, private apiService: ApiService) {}

  ngOnInit() {
    this.token.validate();
    if (this.prescripcion) {
      this.esAuditable = !this.prescripcion.auditoria.procesada && this.isValido('ROLE_AUDITOR');
      if (this.prescripcion.medicamentos.length > 0) {
        this.filtrado = "m";
      } else if (this.prescripcion.procedimientos.length > 0) {
        this.filtrado = "p";
      } else if (this.prescripcion.productosnutricionales.length > 0) {
        this.filtrado = "n";
      } else if (this.prescripcion.serviciosComplementarios.length > 0) {
        this.filtrado = "s";
      } else if (this.prescripcion.dispositivos.length > 0) {
        this.filtrado = "d";
      }
      if (this.prescripcion.auditoria.procesada) {
        this.estadoAuditoria = "text-success";
      } else {
        this.estadoAuditoria = "text-warning";
      }
      if (this.esAuditable && this.prescripcion.prescripcion.rel.rel.identificacion!=null) {
        this.esAuditable = this.prescripcion.prescripcion.rel.rel.estado;
      }
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleMedicamento(med: Medicamento) {
    this.showModalMedicamento.emit({ med: med, presc: this.prescripcion });
  }

  detalleServicioCompl(serv: ServicioComplementario) {
    this.showModalServicio.emit({ serv: serv, presc: this.prescripcion });
  }

  detalleProcedimiento(proc: Procedimiento) {
    this.showModalProcedimiento.emit({ proc: proc, presc: this.prescripcion });
  }

  detalleProductoNutri(prod: ProductoNutricional) {
    this.showModalProducto.emit({ prod: prod, presc: this.prescripcion });
  }

  detalleDispositivoMedic(disp: DispositivoMedico, numero: any) {
    this.showModalDispositivo.emit({ disp: disp, presc: this.prescripcion });
  }

  auditar() {
    let salida = new AuditoriaSalida();

    salida.numPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.tutela = false;
    salida.numIdPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.tipoIdPaciente = this.prescripcion.prescripcion.TipoIDPaciente;

    this.showModalAuditoria.emit({
      salida,
      presc: this.prescripcion
    })

  }

  direccionamientoMedAdd(med: Medicamento) {
    let salida = new DireccionamientoSalida();
    salida.ConTec = med.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.DiagPrinc = this.prescripcion.prescripcion.CodDxPpal;
    salida.TipoTec = "M";
    this.showModalDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion,
      medicamento: med
    });
  }

  buscarTarifa(informacion: any) {
    //this.showModalBuscarTarifas.emit(informacion)
  }

  noDireccionamientoMedAdd(med: Medicamento) {
    let salida = new NoDireccionamientoSalida();
    salida.ConTec = med.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.TipoTec = "M";
    this.showModalNoDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  direccionamientoProcAdd(proc: Procedimiento) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = proc.ConOrden;
    salida.CodSerTecAEntregar = proc.CodCups;
    salida.DescSerTec = proc.rel.descCodCUPS;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.DiagPrinc = this.prescripcion.prescripcion.CodDxPpal;
    salida.TipoTec = "P";
    this.showModalDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  noDireccionamientoProcAdd(proc: Procedimiento) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = proc.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.TipoTec = "P";
    this.showModalNoDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  direccionamientoProdAdd(prod: ProductoNutricional) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = prod.ConOrden;
    salida.CodSerTecAEntregar = prod.DescProdNutr;
    salida.DescSerTec = prod.rel.descDescProdNutr;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.DiagPrinc = this.prescripcion.prescripcion.CodDxPpal;
    salida.TipoTec = "N";
    this.showModalDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  noDireccionamientoProdAdd(prod: ProductoNutricional) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = prod.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.TipoTec = "N";
    this.showModalNoDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  direccionamientoServAdd(serv: ServicioComplementario) {
    let salida = new DireccionamientoSalida();

    salida.ConTec = serv.ConOrden;
    salida.CodSerTecAEntregar = serv.CodSerComp;
    salida.DescSerTec = serv.rel.descCodSerComp;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.DiagPrinc = this.prescripcion.prescripcion.CodDxPpal;
    salida.TipoTec = "S";
    this.showModalDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  noDireccionamientoServAdd(serv: ServicioComplementario) {
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = serv.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.TipoTec = "S";
    this.showModalNoDireccionamientoAdd.emit({
      salida,
      presc: this.prescripcion
    });
  }

  direccionamientoDispAdd(disp: DispositivoMedico, numero: any) {
    console.log(disp, numero)
    let salida = new DireccionamientoSalida();

    salida.ConTec = disp.ConOrden;
    salida.CodSerTecAEntregar = disp.CodDisp;
    salida.DescSerTec = disp.rel.descCodDisp;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.DiagPrinc = this.prescripcion.prescripcion.CodDxPpal;
    salida.TipoTec = "D";
    this.showModalDireccionamientoAdd.emit({
      salida: salida,
      presc: this.prescripcion
    });
  }

  noDireccionamientoDispAdd(disp: DispositivoMedico, numero: any) {
    console.log(disp, numero)
    let salida = new NoDireccionamientoSalida();

    salida.ConTec = disp.ConOrden;

    salida.NoIDPaciente = this.prescripcion.prescripcion.NroIDPaciente;
    salida.TipoIDPaciente = this.prescripcion.prescripcion.TipoIDPaciente;
    salida.NoPrescripcion = this.prescripcion.prescripcion.NoPrescripcion;
    salida.TipoTec = "D";

    this.showModalNoDireccionamientoAdd.emit({
      salida: salida,
      presc: this.prescripcion
    });
  }

  prescripcionPdf() {
    Swal.fire({
      title: 'Generando archivo ... ',
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    this.apiService.prescripcionesService.getPorNumeroPdf(this.eps.identificacion, this.regimen.codigo, this.prescripcion.prescripcion.NoPrescripcion).subscribe(
      response => {
        this.apiService.utilService.downloadFile(response, 'Prescripción - '+ this.prescripcion.prescripcion.NoPrescripcion, 'pdf');
      }, error => {
        console.log(error);
        this.apiService.notifService.error('Error', error);
      }
    )
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
