import { NoDireccionamientoAnularSalida } from "./../../../models/salida/no-direccionamiento-anular-salida.model";
import { ApiService } from "./../../../services/api.service";
import { Input, ViewChild, ElementRef } from "@angular/core";
import { Component, OnInit } from "@angular/core";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { NoDireccionamiento } from "src/app/models/msumin/no-direccionamiento-model";

@Component({
  selector: "app-modal-no-direccionamientos-detalle",
  templateUrl: "./modal-no-direccionamientos-detalle.component.html",
  styleUrls: ["./modal-no-direccionamientos-detalle.component.scss"]
})
export class ModalNoDireccionamientosDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  direccionamientos: NoDireccionamiento[];
  cargando: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.direccionamientos = [];
      this.cargando = true;
      this.apiService.noDireccionamientosService
        .getPrescripcion(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion
        )
        .subscribe(
          data => {
            if (data.noDireccionamientos) {
              this.direccionamientos = data.noDireccionamientos;
            }
            this.cargando = false;
          },
          error => {
            this.apiService.notifService.error("Error", error);
            this.cargando = false;
          }
        );
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  anular(dir: NoDireccionamiento) {
    let entrada = new NoDireccionamientoAnularSalida();

    entrada.conTec = dir.ConTec;
    entrada.idNoDireccionamientoRef = dir.IDNODireccionamiento;
    entrada.idRef = dir.ID;
    entrada.numPrescripcion = dir.NoPrescripcion;
    entrada.tipoTec = dir.TipoTec;

    this.apiService.noDireccionamientosService
      .anular(this.eps.identificacion, this.regimen.codigo, entrada)
      .subscribe(data => {
        this.cargando=true;
        setTimeout(() => this.cargarInformacion(), 1500);
      }, error => {
        this.apiService.notifService.error("Error", error);
      });
  }
}
