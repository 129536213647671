import { NAME_APP } from './../../url.constants';
import { ModalEpsRegimenesComponent } from './../modals/modal-eps-regimenes/modal-eps-regimenes.component';
import { Router } from '@angular/router';
import { ApiService } from './../../services/api.service';
import { Eps } from './../../models/eps.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-main-epss',
  templateUrl: './main-epss.component.html',
  styleUrls: ['./main-epss.component.scss']
})
export class MainEpssComponent implements OnInit {

  epss: Eps[] = [];

  @ViewChild('modalEpsRegimenes', { static: false }) modalEpsRegimenes: ModalEpsRegimenesComponent;

  constructor(private token: TokenStorageService, private router: Router, private apiService: ApiService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Epss`);
    this.token.validate();
    this.apiService.epsService.getEps()
      .subscribe(data => {
        this.epss = [];
        this.epss.push(data.eps);
      });
  }

  regimenesEps(eps: Eps) {
    this.modalEpsRegimenes.eps = eps;
    this.modalEpsRegimenes.showModal();
  }

  updateEps(eps: Eps) {
    for (var i in this.epss) {
      if(this.epss[i].identificacion = eps.identificacion) {
        this.epss[i].regimenes = eps.regimenes;
      }
    }
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
