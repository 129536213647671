import { getNombreClasificacionTarifa, getNombreCodigoTarifa } from './../../../models/tarifa.model';
import { ContratosPrestadores } from './../../../models/contratos-prestadores.model';
import { Component, OnInit, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
import { Tarifa } from 'src/app/models/tarifa.model';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { Regimen } from 'src/app/models/regimen.model';
import { Eps } from 'src/app/models/eps.model';
import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { Proveedor } from 'src/app/models/proveedor.model';

@Component({
  selector: 'app-modal-buscar-tarifas',
  templateUrl: './modal-buscar-tarifas.component.html',
  styleUrls: ['./modal-buscar-tarifas.component.scss']
})
export class ModalBuscarTarifasComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public proveedor: Proveedor;
  @Input() public direccionamiento: any;
  @Output() public showModalDireccionamientoAdd = new EventEmitter();

  contratos: ContratosPrestadores[] = []
  tarifas: any[] = [];
  cargandoTarifas: boolean = false;
  busquedaTarifas: boolean = false;

  tarifaForm: FormGroup;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.tarifaForm = this.fb.group({
        nombre: new FormControl(''),
        contrato: new FormControl(''),
      });
      this.getContratos()
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    let info = this.direccionamiento;
    info['proveedor'] = this.proveedor;
    this.showModalDireccionamientoAdd.emit(info);
  }

  public seleccionarTarifa(tarifa: Tarifa) {
    this.busquedaTarifas=false;
    this.modalClose.nativeElement.click();
    let info = this.direccionamiento;
    info['tarifa'] = tarifa;
    info['proveedor'] = this.proveedor;
    this.showModalDireccionamientoAdd.emit(info);
  }

  buscarTarifas() {
    this.tarifas = [];
    this.cargandoTarifas = true;
    this.busquedaTarifas = true;
    let {nombre, contrato } = this.tarifaForm.value;
    this.apiService.tarifasService
      .getTarifas(contrato, nombre, this.direccionamiento.salida.TipoTec)
      .subscribe(data => {
        data.tarifas.forEach(tarifa => {
          tarifa.nombre_clasificacion = getNombreClasificacionTarifa(tarifa)
          tarifa.nombre_codigo = getNombreCodigoTarifa(tarifa)
        });
        this.tarifas = data.tarifas;
        this.cargandoTarifas = false;
      }, error => {
        this.cargandoTarifas = false;
        console.log(error);
      });
  }

  getContratos(id=null) {
    if (this.proveedor) {
      this.apiService.proveedoresService.getContratosProveedor(this.proveedor.id, this.regimen.codigo).subscribe(
        data => {
          this.contratos = data
          if (this.contratos && this.contratos.length>0) {
            this.tarifaForm.patchValue({
              contrato: this.contratos[0].idContratoPrestadores
            });
          }
        }
      )
    }
  }

}
