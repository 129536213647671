export class NoDireccionamientoSalida {
  NoPrescripcion: string;
	TipoTec: string;
	ConTec: number;
	TipoIDPaciente: string;
	NoIDPaciente: string;
	CausaNoEntrega: number;
	NoPrescripcionAsociada: string;
	ConTecAsociada: number;
}
