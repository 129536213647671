import { FacturacionesApi } from './../models/containers/facturaciones-api.model';
import { HttpClient } from '@angular/common/http';
import { API_REST } from './../url.constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FacturacionesService {
  baseUrl = API_REST + "facturacion/";

  constructor(private http: HttpClient) {}

  byFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<FacturacionesApi>(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}?format=json`
    );
  }

  byFechaExcel(nit: string, regimen: string, fecha: string) {
    return this.http.get(
      `${this.baseUrl}byFecha/${nit}/${regimen}/${fecha}?format=xlsx`,
      { responseType: "blob" }
    );
  }

  byPrescripcion(nit: string, regimen: string, prescripcion: string) {
    return this.http.get<FacturacionesApi>(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${prescripcion}?format=json`
    );
  }

  byPrescripcionExcel(nit: string, regimen: string, prescripcion: string) {
    return this.http.get(
      `${this.baseUrl}byPrescripcion/${nit}/${regimen}/${prescripcion}?format=xlsx`,
      { responseType: "blob" }
    );
  }
}
