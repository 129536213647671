import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { DispositivoMedico } from './../../../models/mpresc/dispositivo-medico.model';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-modal-dispositivo-medico-detalle',
  templateUrl: './modal-dispositivo-medico-detalle.component.html',
  styleUrls: ['./modal-dispositivo-medico-detalle.component.scss']
})
export class ModalDispositivoMedicoDetalleComponent implements OnInit {
  @Input() public dispositivo: DispositivoMedico;
  @Input() public prescripcion: PrescripcionApi;
  @Output() public showModalPrescripcion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalPrescripcion.emit(this.prescripcion);
  }
}
