import { ContratosPrestadores } from './../models/contratos-prestadores.model';
import { Observable } from 'rxjs';
import { ProveedoresApi } from "./../models/containers/proveedores-api.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";
import { Proveedor } from '../models/proveedor.model';

@Injectable({
  providedIn: "root"
})
export class ProveedoresService {
  baseUrl = API_REST + "proveedores/";

  constructor(private http: HttpClient) {}

  getProveedoresList() {
    return this.http.get<ProveedoresApi>(`${this.baseUrl}`);
  }

  getProveedor(codHab: string) {
    return this.http.get<Proveedor>(`${this.baseUrl}${codHab}`);
  }

  getContratosProveedor(idProveedor: number, regimen: string) {
    return this.http.get<ContratosPrestadores[]>(`${this.baseUrl}contratos/${idProveedor}/${regimen}`);
  }

  getDynamicList(prov: Proveedor=null) {
    return Observable.create(obs => {
      let list = [];
      this.getProveedoresList().subscribe(data => {
        data.proveedores.forEach(element => {
          list.push({
            id: JSON.stringify(element),
            text: `[${element.identificacion}] ${element.nombre} (${element.municipio})`,
            selected: prov && prov.id == element.id?true: false
          });
        });
        obs.next(list);
        obs.complete();
      });
    });
  }
}
