export class AuditoriaOrdenSalida {
  conOrden: number;
	tipoTec: string;
	aprobada: boolean;
  descripcion: string;
  totalEntregas: number;
  tipoPrestacion: number;
  alerta: boolean;

  constructor(conOrden: number,
    tipoTec: string,
    aprobada: boolean,
    descripcion: string, totalEntregas: number, tipoPrestacion: number, alerta: boolean = false) {
      this.conOrden = conOrden;
      this.tipoTec = tipoTec;
      this.aprobada = aprobada;
      this.descripcion = descripcion;
      this.totalEntregas = totalEntregas;
      this.tipoPrestacion = tipoPrestacion;
      this.alerta = alerta;
    }
}
