import { SuministroAnularSalida } from './../../../models/salida/suministro-anular-salida.model';
import { ApiService } from './../../../services/api.service';
import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Suministro } from 'src/app/models/msumin/suministro.model';
import { Eps } from 'src/app/models/eps.model';
import { Regimen } from 'src/app/models/regimen.model';

@Component({
  selector: 'app-modal-suministros-detalle',
  templateUrl: './modal-suministros-detalle.component.html',
  styleUrls: ['./modal-suministros-detalle.component.scss']
})
export class ModalSuministrosDetalleComponent implements OnInit {
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;
  @Output() public showModalSuministro = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  suministros: Suministro[];
  cargando: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.suministros = [];
      this.cargando = true;
      if (this.tutela) {
        this.apiService.suministrosService
          .byTutela(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.suministros) {
                this.suministros = data.suministros;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );
      } else {
        this.apiService.suministrosService
          .byPrescripcion(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              if (data.suministros) {
                this.suministros = data.suministros;
              }
              this.cargando = false;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              this.cargando = false;
            }
          );
      }
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleSuministro(rep: Suministro) {
    this.showModalSuministro.emit({ sum: rep, presc: this.prescripcion});
  }

  anular(dir: Suministro) {
    let entrada = new SuministroAnularSalida();

    entrada.conTec = dir.ConTec;
    entrada.id = dir.ID;
    entrada.idNoSuministro = dir.IDSuministro;
    entrada.numPrescripcion = dir.NoPrescripcion;
    entrada.tipoTec = dir.TipoTec;

    this.apiService.suministrosService
      .anular(this.eps.identificacion, this.regimen.codigo, entrada)
      .subscribe(
        data => {
          this.cargando = true;
          setTimeout(() => this.cargarInformacion(), 1500);
        },
        error => {
          this.apiService.notifService.error("Error", error);
        }
      );
  }

}
