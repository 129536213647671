import { TokenPrescApi } from "./../models/containers/token-presc-api.model";
import { HttpClient } from "@angular/common/http";
import { API_REST } from "./../url.constants";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TokenService {
  baseUrl = API_REST + "token/";

  constructor(private http: HttpClient) {}

  updateToken(codigo: string, token: string) {
    return this.http.put<TokenPrescApi>(
      `${this.baseUrl}prescUpdate/${codigo}`,
      token
    );
  }
}
