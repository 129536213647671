import { NAME_APP } from "./../../url.constants";
import { ModalTokenEditComponent } from "./../modals/modal-token-edit/modal-token-edit.component";
import { TokenPresc } from "./../../models/token-presc.model";
import { ApiService } from "./../../services/api.service";
import { Eps } from "./../../models/eps.model";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-main-token",
  templateUrl: "./main-token.component.html",
  styleUrls: ["./main-token.component.scss"]
})
export class MainTokenComponent implements OnInit {
  epss: Eps[] = [];
  eps: Eps = null;
  tokens: TokenPresc[] = [];

  @ViewChild("modalTokenEdit", { static: false })
  modalTokenEdit: ModalTokenEditComponent;

  constructor(
    private token: TokenStorageService,
    private apiService: ApiService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Tokens`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      this.epss = [];
      this.epss.push(data.eps);
      this.eps = data.eps;
      this.cargarTokens();
    });
  }

  onChangeEps(eps: Eps) {
    this.eps = eps;
    this.cargarTokens();
  }

  cargarTokens() {
    this.tokens = [];
    if (this.eps) {
      this.eps.regimenes.forEach(element => {
        let token = new TokenPresc();
        token.token = element.tokenPrescripcion.token;
        token.regimen = element;
        this.tokens.push(token);
      });
    }
  }

  editToken(token: TokenPresc) {
    this.modalTokenEdit.token = token;
    this.modalTokenEdit.showModal();
  }

  updateToken(token: TokenPresc) {
    for (var i in this.tokens) {
      if (this.tokens[i].regimen.codigo == token.regimen.codigo) {
        this.tokens[i].token = token.token;
      }
    }
  }

  isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
