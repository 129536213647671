import { TutelasAfiliadoApi } from './../models/containers/tutelas-api.model';
import { HttpClient } from '@angular/common/http';
import { API_REST } from './../url.constants';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TutelasAfiliadoService {
  baseUrl = API_REST + "tutelas/";

  constructor(private http: HttpClient) {}

  getByIdAfiliado(idAfiliado: number, numeroTutela: string) {
    return this.http.get<TutelasAfiliadoApi>(`${this.baseUrl}${idAfiliado}/${numeroTutela}`);
  }
}
